<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        v-if="ptPayment"
        :cols="12"
        style="padding-bottom: 0;"
      >
        <div class="pt-payment-container">
          <div class="warning">
            <div>
              <div>
                {{ str['monthly_payment'] }} {{ ptPayment.month_year }}
              </div>
              <div>
                {{ str['value'] }}: {{ ptPayment.amount }}€
              </div>
            </div>
            <div v-if="ptPayment.remarks">
              <i class="mdi mdi-information"
                @click="dialogPtPaymentRemarks = true"></i>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-if="birthdaysVisible"
        :cols="12"
        :lg="tablesCols"
        :md="tablesCols"
        :sm="12"
        :style="{'order': orderTables && tableOrder['birthdays'] ? tableOrder['birthdays'] : null}"
      >
        <base-material-card
          color="secondary"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              {{ tablesNames && tablesNames['birthdays'] ? tablesNames['birthdays'] : str['birthdays'] }}
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="birthdaysHeaders"
              :items="birthdays"
              :items-per-page="tableItemsPerPage['birthdays']"
              :search="birthdaysSearch"
              :no-data-text="tablesLoaded.indexOf('birthdays') === -1 ? str['processing'] : str['birthdays_empty']"
              :no-results-text="tablesLoaded.indexOf('birthdays') === -1 ? str['processing'] : str['birthdays_empty']"
              @click:row="openClient"
              @update:items-per-page="function(itemsPerPageValue) {updateTableItemsPerPage('birthdays', itemsPerPageValue)}"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-text-field
                    v-model="birthdaysSearch"
                    append-icon="mdi-magnify"
                    :label="str['search']"
                    single-line
                    hide-details
                  />
                  <v-spacer />
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  v-if="showChatButton"
                  small
                  class="mr-0"
                  color="secondary"
                  @click.stop="openClientChat(item)"
                >
                  mdi-chat
                </v-icon>
              </template>
              <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col
        v-if="initialQuizFeedbacksVisible"
        :cols="12"
        :lg="tablesCols"
        :md="tablesCols"
        :sm="12"
        :style="{'order': orderTables && tableOrder['initial_quizs'] ? tableOrder['initial_quizs'] : null}"
      >
        <base-material-card
          color="secondary"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              {{ tablesNames && tablesNames['initial_quizs'] ? tablesNames['initial_quizs'] : str['initial_physical_evaluations'] }}
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="initialQuizFeedbacksHeaders"
              :items="initialQuizFeedbacksFiltered"
              :items-per-page="tableItemsPerPage['initial_quizs']"
              :search="initialQuizFeedbacksSearch"
              :no-data-text="tablesLoaded.indexOf('initial_quizs') === -1 ? str['processing'] : str['initial_physical_evaluations_empty']"
              :no-results-text="tablesLoaded.indexOf('initial_quizs') === -1 ? str['processing'] : str['initial_physical_evaluations_empty']"
              @click:row="openPhysicalEvaluation"
              @update:items-per-page="function(itemsPerPageValue) {updateTableItemsPerPage('initial_quizs', itemsPerPageValue)}"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-text-field
                    v-if="!showFeedbackEmployees"
                    v-model="initialQuizFeedbacksSearch"
                    append-icon="mdi-magnify"
                    :label="str['search']"
                    single-line
                    hide-details
                  />
                  <div
                    v-if="showFeedbackEmployees"
                    class="row-flex-align"
                    style="width: 100%; max-width: 500px;"
                  >
                    <div
                      class="row-full-width"
                    >
                      <v-text-field
                        v-model="initialQuizFeedbacksSearch"
                        append-icon="mdi-magnify"
                        :label="str['search']"
                        single-line
                        hide-details
                      />
                    </div>
                    <div
                      class="pl-4 row-full-width"
                    >
                      <v-autocomplete
                        v-model="initialQuizEmployeeFilter"
                        :label="str['employee']"
                        item-text="name"
                        item-value="id"
                        :items="employeesData"
                        :no-data-text="str['no_data']"
                        clearable
                        hide-details
                      />
                    </div>
                  </div>
                  <v-spacer />
                </v-toolbar>
              </template>
              <template v-slot:item.employees="{ item }">
                <div v-if="item.employees">
                  <span
                    v-if="!item.employees.length"
                  >
                    -
                  </span>
                  <span
                    v-for="(employeeId, employeeIndex) in item.employees"
                    :key="employeeId"
                  >
                    {{ (employeeIndex > 0 ? ' | ' : '') + (employeesDict[employeeId] ? employeesDict[employeeId] : '-') }}
                  </span>
                </div>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  v-if="showChatButton"
                  small
                  class="mr-0"
                  color="secondary"
                  @click.stop="openClientChat(item)"
                >
                  mdi-chat
                </v-icon>
              </template>
              <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col
        v-if="registersVisible"
        :cols="12"
        :lg="tablesCols"
        :md="tablesCols"
        :sm="12"
        :style="{'order': orderTables && tableOrder['registers'] ? tableOrder['registers'] : null}"
      >
        <base-material-card
          color="secondary"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              {{ tablesNames && tablesNames['registers'] ? tablesNames['registers'] : str['pending_validation_registers'] }}
            </div>
          </template>
          <v-toolbar
            v-if="showRegistersTableTabs"
            flat
          >
            <v-tabs
              v-model="registersTableTabActive"
              background-color="transparent"
              color="secondary"
              style="margin-top: 0;"
              grow
              show-arrows
            >
              <v-tab>
                {{ str['completed'] }}
              </v-tab>
              <v-tab
                v-if="showCompleteRegistersChatMessageTab"
              >
                {{ str['completed_with_chat_message'] }}
              </v-tab>
              <v-tab>
                {{ str['incompleted'] }}
              </v-tab>
            </v-tabs>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-data-table
              :headers="registersHeaders"
              :items="registersFiltered"
              :items-per-page="tableItemsPerPage['registers']"
              :search="registersSearch"
              :no-data-text="tablesLoaded.indexOf('registers') === -1 ? str['processing'] : str['pending_validation_registers_empty']"
              :no-results-text="tablesLoaded.indexOf('registers') === -1 ? str['processing'] : str['pending_validation_registers_empty']"
              @click:row="openRegister"
              @update:items-per-page="function(itemsPerPageValue) {updateTableItemsPerPage('registers', itemsPerPageValue)}"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-text-field
                    v-model="registersSearch"
                    append-icon="mdi-magnify"
                    :label="str['search']"
                    single-line
                    hide-details
                  />
                  <v-spacer />
                </v-toolbar>
              </template>
              <template v-slot:item.paym_sub_status="{ item }">
                <span v-bind:style="{'color': getPaymSubStatus(item.paym_sub_status).color}">
                  {{ getPaymSubStatus(item.paym_sub_status).label }}
                </span>
              </template>
              <template v-slot:item.date="{ item }">
                {{ convertUtcDate(item.date) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  v-if="showChatButton && showRegisterChatButton"
                  small
                  class="mr-0"
                  color="secondary"
                  @click.stop="openClientChat(item, true)"
                >
                  mdi-chat
                </v-icon>
              </template>
              <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
              </template>
            </v-data-table>
            <v-dialog
              v-model="dialogRegister"
              persistent
              max-width="750px"
            >
              <v-card>
                <v-card-title
                  v-if="showChatButton && showRegisterChatButton"
                  style="display: flex; justify-content: space-between;"
                >
                  <span class="headline">
                    {{ str['registration_validation'] }}
                  </span>
                  <v-btn
                    v-if="isHotmart && register && !register.paym_sub && !registerPayment"
                    color="orange"
                    @click="refreshHotmartConnections(register)"
                  >
                    Tentar conectar com Hotmart
                  </v-btn>
                  <v-btn
                    color="blue"
                    @click="openClientChat(register, true)"
                  >
                    {{ str['send_message'] }}
                  </v-btn>
                </v-card-title>
                <v-card-title
                  v-if="!showChatButton || !showRegisterChatButton"
                >
                  <span class="headline">
                    {{ str['registration_validation'] }}
                  </span>
                </v-card-title>

                <v-card-text>
                  <div
                    v-if="register && register.paym_sub_status !== 0"
                    style="margin-bottom: 10px;"
                  >
                    <span
                      v-bind:style="{'color': getPaymSubStatus(register.paym_sub_status).color}"
                    >
                      {{ getPaymSubStatus(register.paym_sub_status).label }}
                    </span>
                  </div>
                  <div
                    v-if="!register"
                    style="height: 340px; padding: 100px 0; text-align: center;"
                  >
                    <v-progress-circular
                      :size="50"
                      color="primary"
                      indeterminate
                    />
                  </div>
                  <div v-if="register && register.promo_code && register.promo_code.indexOf('RM-') > -1">
                    <h4 class="warning--text">
                      {{ str['return'] }} - {{ str['manual'] }}
                    </h4>
                  </div>
                  <div v-if="register && register.promo_code && register.promo_code.indexOf('RS-') > -1">
                    <h4 class="warning--text">
                      {{ str['return'] }} - {{ str['subscription'] }}
                    </h4>
                  </div>
                  <div
                    v-if="register"
                  >
                    <v-tabs
                      v-model="registerTabActive"
                      background-color="transparent"
                      color="secondary"
                      style="margin-top: 0;"
                      grow
                      @change="changeRegisterTab"
                    >
                      <v-tab
                        v-for="registerTab in registerTabs"
                        :key="registerTab.id"
                      >
                        {{ registerTab.name }}
                      </v-tab>
                    </v-tabs>

                    <v-tabs-items
                      v-model="registerTabActive"
                    >
                      <v-tab-item
                        v-for="registerTab in registerTabs"
                        :key="registerTab.id"
                      >
                        <v-card
                          v-if="registerTabs[registerTabActive].id === 'details'"
                          color="basil"
                          style="margin-top: 0;"
                          flat
                        >
                          <v-card-text>
                            <v-list-item>
                              <v-list-item-content
                                style="margin: 0 auto;"
                              >
                                <v-list-item-title
                                  v-for="(detail) in registerDetails"
                                  v-show="!detail.hidden"
                                  :key="detail.id"
                                  cols="12"
                                  style="margin: 5px 0;"
                                >
                                  <span
                                    v-if="detail.id !== 'notes'"
                                    class="font-weight-black"
                                    style="margin-right: 5px;"
                                  >
                                    {{ detail.title }}:
                                  </span>
                                  <span
                                    v-if="detail.id === 'notes'"
                                    class="font-weight-black"
                                    style="margin-right: 5px;"
                                  >
                                    {{ detail.title }}
                                  </span>
                                  <span
                                    v-if="detail.id !== 'notes'"
                                    class="font-weight-regular"
                                  >
                                    {{ detail.value }}
                                  </span>
                                  <span
                                    v-if="detail.id === 'notes'"
                                    class="font-weight-regular"
                                    v-html="detail.value"
                                  ></span>
                                  <v-icon
                                    v-if="detail.editable && (!detail.editable.exclude_paym_sub_status || (detail.editable.exclude_paym_sub_status && detail.editable.exclude_paym_sub_status.indexOf(register.paym_sub_status) === -1))"
                                    style="margin-left: 3px;"
                                    @click="editRegister(detail)"
                                    color="warning"
                                    class="cursor-hover"
                                  >
                                    mdi-pencil-box
                                  </v-icon>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>

                            <div
                              v-if="showDetailsQuestionsSameTab && registerQuestions && registerQuestions.length"
                              class="font-weight-black"
                            >
                              {{ str['quiz'] }}
                            </div>
                            <v-list-item
                              v-if="showDetailsQuestionsSameTab && registerQuestions && registerQuestions.length"
                            >
                              <v-list-item-content
                                style="margin: 0 auto; padding-top: 0; padding-bottom: 0;"
                              >
                                <v-list-item-title
                                  v-for="(question) in registerQuestions"
                                  :key="question.id"
                                  cols="12"
                                  style="margin: 10px 0; white-space: initial;"
                                >
                                  <div
                                    class="font-weight-black"
                                    style="margin-bottom: 5px;"
                                  >
                                    {{ str[question.title] ? str[question.title] : question.title }}
                                  </div>
                                  <div class="font-weight-regular">
                                    {{ question.value }}
                                  </div>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card-text>
                        </v-card>
                        <v-card
                          v-if="!showDetailsQuestionsSameTab && registerTabs[registerTabActive].id === 'questions'"
                          color="basil"
                          style="margin-top: 0;"
                          flat
                        >
                          <v-card-text>
                            <v-list-item>
                              <v-list-item-content
                                style="margin: 0 auto;"
                              >
                                <v-list-item-title
                                  v-for="(question) in registerQuestions"
                                  :key="question.id"
                                  cols="12"
                                  style="margin: 10px 0; white-space: initial;"
                                >
                                  <div
                                    class="font-weight-black"
                                    style="margin-bottom: 5px;"
                                  >
                                    {{ str[question.title] ? str[question.title] : question.title }}
                                  </div>
                                  <div class="font-weight-regular">
                                    {{ question.value }}
                                  </div>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card-text>
                        </v-card>
                        <v-card
                          v-if="registerTabs[registerTabActive].id === 'photos'"
                          color="basil"
                          style="margin-top: 0;"
                          flat
                        >
                          <v-card-text v-if="registerAssetsProcessing">
                            <div
                              style="height: 340px; padding: 100px 0; text-align: center;"
                            >
                              <v-progress-circular
                                :size="50"
                                color="primary"
                                indeterminate
                              />
                            </div>
                          </v-card-text>
                          <v-card-text v-if="!registerAssetsProcessing">
                            <div class="icon-photo-download row-flex-align">
                              <div
                                v-if="updateRegisterPhotosEnabled"
                                class="icon-image-field cursor-hover mr-2"
                              >
                                <i
                                  class="mdi mdi-file-replace"
                                  style="font-size: 1em;"
                                />
                                <input
                                  id="register-input-photos"
                                  type="file"
                                  @change="updateRegisterPhotos"
                                  accept=".png,.jpg,.jpeg"
                                >
                              </div>
                              <i
                                class="mdi mdi-tray-arrow-down"
                                style="font-size: 1.2em;"
                                @click="downloadImage(registerPhotos[currentRegisterPhotoIndex].photo)"
                              />
                            </div>
                            <v-carousel
                              style="margin: auto; height: 400px; width: 400px;"
                              v-model="currentRegisterPhotoIndex"
                            >
                              <v-carousel-item
                                v-for="(photo,i) in registerPhotos"
                                :key="i"
                                :src="photo.photo"
                                contain
                                reverse-transition="fade-transition"
                                transition="fade-transition"
                                height="300px"
                              />
                            </v-carousel>
                          </v-card-text>
                        </v-card>
                        <v-card
                          v-if="registerTabs[registerTabActive].id === 'videos'"
                          color="basil"
                          style="margin-top: 0;"
                          flat
                        >
                          <v-card-text>
                            <div style="text-align: center; min-height: 200px;">
                              <div
                                v-for="(videoItem, index) in registerVideos"
                                :key="index"
                                style="padding: 10px 0; position: relative;"
                              >
                                <video
                                  :src="videoItem"
                                  style="max-width: 80%; max-height: 500px;"
                                  controls
                                ></video>
                              </div>
                            </div>
                          </v-card-text>
                        </v-card>
                        <v-card
                          v-if="registerTabs[registerTabActive].id === 'payment'"
                          color="basil"
                          style="margin-top: 0;"
                          flat
                        >
                          <div
                            v-if="!register.paym_sub && !registerPayment && !register.paym_pri"
                            style="padding: 20px; min-height: 300px; color: #888;"
                          >
                            <div
                              v-if="!addRegisterPaymentEnabled"
                            >
                              {{ str['payment_empty'] }}
                            </div>
                            <div
                              v-if="addRegisterPaymentEnabled"
                            >
                              <div v-if="addRegisterPaymentFields && addRegisterPaymentFields.length">
                                {{ str['add_payment_proof_fields_info'] }}
                              </div>
                              <div
                                v-if="addRegisterPaymentFields && addRegisterPaymentFields.length"
                                class="pt-2 pb-10"
                                style="max-width: 200px; margin: 0 auto;"
                              >
                                <div
                                  v-for="item in addRegisterPaymentFields"
                                  :key="item.id"
                                >
                                  <v-text-field
                                    v-if="item.type === 'input' && !item.inputType"
                                    v-model="item.value"
                                    :label="str[item.title] ? str[item.title] : item.title"
                                    hide-details
                                  />
                                  <v-text-field
                                    v-if="item.type === 'input' && item.inputType === 'numeric'"
                                    v-model="item.value"
                                    :label="str[item.title] ? str[item.title] : item.title"
                                    :disabled="item.disabled ? true : false"
                                    hide-details
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                  />
                                  <v-textarea
                                    v-if="item.type === 'textarea'"
                                    v-model="item.value"
                                    :label="str[item.title] ? str[item.title] : item.title"
                                    :disabled="item.disabled ? true : false"
                                    hide-details
                                    rows="3"
                                  />
                                  <v-autocomplete
                                    v-if="item.type === 'select'"
                                    v-model="item.value"
                                    :label="str[item.title] ? str[item.title] : item.title"
                                    item-text="label"
                                    item-value="value"
                                    :items="item.items"
                                    :no-data-text="str['no_data']"
                                  />
                                </div>
                              </div>
                              <div
                                class="row-flex-align-center"
                              >
                                <div style="position: relative;">
                                  <v-btn
                                    color="blue"
                                    class="mr-0"
                                  >
                                    {{ str['upload_proof_payment'] }}
                                    
                                  </v-btn>
                                  <input
                                    id="register-payment-image"
                                    type="file"
                                    @change="addRegisterPaymentProof"
                                    accept=".png,.jpg,.jpeg"
                                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: 0;"
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="paymentsViewPermission && isHotmart && register.paym_pri"
                            style="font-size: 18px; padding: 20px; text-align: center;"
                          >
                            {{ register.paym_pri }}
                          </div>
                          <div
                            v-if="register.paym_sub && registerPayment && registerPayment.type !== registerPaymentProofType"
                            style="min-height: 100px; font-size: 18px; padding: 20px; text-align: center;"
                          >
                            <div v-if="paymentsViewPermission && registerPayment.type === registerPaymentStripeType">
                              <b>{{ str['type'] }}:</b> {{ str['stripe_subscription'] }}
                            </div>
                            <div v-if="paymentsViewPermission && registerPayment.type === registerPaymentCheckoutType">
                              <b>{{ str['type'] }}:</b> {{ str['checkout_subscription'] }}
                            </div>
                            <div v-if="paymentsViewPermission && registerPayment.type === registerPaymentHotmartType">
                              <b>{{ str['type'] }}:</b> {{ str['hotmart_subscription'] }}
                            </div>
                            <div v-if="paymentsViewPermission && registerPayment.type === registerPaymentVWType">
                              <b>{{ str['type'] }}:</b> {{ str['viva_wallet'] }}
                            </div>
                            <div v-if="paymentsViewPermission && registerPayment.type !== registerPaymentHotmartType && registerPayment.type !== registerPaymentVWType">
                              <b>{{ str['subscription'] }}:</b> {{ getSubscriptionLabel(register.paym_subt) }}
                            </div>
                            <div v-if="register.paym_info">
                              <b>{{ str['plan'] }}:</b> {{ paymentsViewPermission ? register.paym_info : register.paym_info.split('(')[0].trim() }}
                            </div>
                            <div v-if="paymentsViewPermission && registerPayment.date">
                              <b>{{ str['date'] }}:</b> {{ registerPayment.date }}
                            </div>
                            <div v-if="paymentsViewPermission">
                              <b>{{ str['value'] }}:</b> {{ register.value }} {{ registerCurrency }}
                            </div>
                            <div v-if="paymentsViewPermission && registerPayment.description && registerPayment.type === registerPaymentVWType">
                              <b>{{ str['payment_method'] }}:</b> {{ registerPayment.description }}
                            </div>
                            <div v-if="paymentsViewPermission && (registerPayment.type === registerPaymentHotmartType || registerPayment.type === registerPaymentVWType) && registerPayment.cha_id">
                              <b>{{ str['transaction'] }}:</b> {{ registerPayment.cha_id }}
                              <i
                                class="mdi mdi-content-copy"
                                style="margin-left: 20px; cursor: pointer;"
                                @click="copyTextToClipboard(registerPayment.cha_id)"
                              />
                            </div>
                            <div v-if="register.paym_invoice_url && registerPayment.type !== registerPaymentCheckoutType && registerPayment.type !== registerPaymentHotmartType">
                              <b>{{ str['link_validate_payment'] }}:</b> {{ register.paym_invoice_url }}
                              <i
                                class="mdi mdi-content-copy"
                                style="margin-left: 20px; cursor: pointer;"
                                @click="copyTextToClipboard(register.paym_invoice_url)"
                              />
                            </div>
                            <div v-if="paymentsViewPermission && showInvoice">
                              <b>{{ str['invoice'] }}: </b>
                              <span v-if="registerPayment.invoice">
                                {{ registerPayment.invoice }}
                              </span>
                              <v-icon
                                v-if="registerPayment.invoice && registerPayment.invoice_url"
                                style="margin-left: 3px; transform: translateY(-1px);"
                                @click="openLink(registerPayment.invoice_url)"
                                color="secondary"
                                class="cursor-hover"
                              >
                                mdi-eye
                              </v-icon>
                              <a
                                v-if="!registerPayment.invoice"
                                @click="generateInvoice()"
                                class="link-text"
                                color="secondary"
                              >
                                {{ str['generate_invoice'] }}
                              </a>
                            </div>
                          </div>
                          <div
                            v-if="paymentsViewPermission && registerPayment && registerPayment.proof && (!register.paym_sub || registerPayment.type === registerPaymentProofType) && (registerPayment.value || registerPayment.description || (showRegisterPlan && register.paym_subt))"
                            class="pt-3"
                          >
                            <div v-if="registerPayment.value">
                              <b>{{ str['value'] }}:</b> {{ registerPayment.value }}{{ registerCurrency }}
                            </div>
                            <div v-if="registerPayment.description">
                              <b>{{ str['payment_method'] }}:</b> {{ registerPayment.description }}
                            </div>
                            <div v-if="showRegisterPlan && register.paym_subt">
                              <b>{{ str['plan'] }}:</b> {{ register.paym_subt }}
                            </div>
                            <div v-if="paymentsViewPermission && showInvoice">
                              <b>{{ str['invoice'] }}: </b>
                              <span v-if="registerPayment.invoice">
                                {{ registerPayment.invoice }}
                              </span>
                              <v-icon
                                v-if="registerPayment.invoice && registerPayment.invoice_url"
                                style="margin-left: 3px; transform: translateY(-1px);"
                                @click="openLink(registerPayment.invoice_url)"
                                color="secondary"
                                class="cursor-hover"
                              >
                                mdi-eye
                              </v-icon>
                              <a
                                v-if="!registerPayment.invoice"
                                @click="generateInvoice()"
                                class="link-text"
                                color="secondary"
                              >
                                {{ str['generate_invoice'] }}
                              </a>
                            </div>
                          </div>
                          <v-card-text
                            v-if="registerPayment && registerPayment.proof && (!register.paym_sub || registerPayment.type === registerPaymentProofType)"
                            style="position: relative; padding-top: 40px; margin-top: 10px;"
                          >
                            <div
                              class="icon-photo-download"
                            >
                              <i
                                class="mdi mdi-tray-arrow-down"
                                style="font-size: 1.2em;"
                                @click="downloadImage(registerPayment.proof)"
                              />
                            </div>
                            <div
                              v-if="register.paym_sub_status === registerPaymentManualType || registerPayment.type === registerPaymentProofType"
                              class="icon-photo-delete"
                            >
                              <i
                                class="mdi mdi-trash-can-outline"
                                style="font-size: 1.2em;"
                                @click="deleteRegisterProof(registerPayment)"
                              />
                            </div>
                            <v-img
                              contain
                              :src="registerPayment.proof"
                              :alt="str['payment']"
                              style="margin: 0 auto;"
                              width="500px"
                            />
                          </v-card-text>
                          <div
                            v-if="registerPayment && registerPayment.type === registerPaymentPromoFreeType"
                            style="padding: 20px; min-height: 270px;"
                          >
                            <div v-if="registerPayment.promo_code">
                              <b>{{ str['promotional_code'] }}:</b> {{ registerPayment.promo_code }}
                            </div>
                          </div>
                        </v-card>
                        <v-card
                          v-if="registerTabs[registerTabActive].id === 'measurements'"
                          color="basil"
                          style="margin-top: 0;"
                          flat
                        >
                          <v-card-text>
                            <v-list-item>
                              <v-list-item-content
                                style="margin: 0 auto;"
                              >
                                <v-list-item-title
                                  v-for="(measurement) in register.measurements"
                                  :key="measurement.id"
                                  cols="12"
                                  style="margin: 0 0 5px; white-space: initial;"
                                >
                                  <div
                                    v-if="measurement.parent"
                                    class="font-weight-black"
                                    style="margin: 10px 0 7px; font-size: 16px; text-decoration: underline;"
                                  >
                                    {{ measurement.parent }}
                                  </div>
                                  <div>
                                    <span class="font-weight-black">
                                      {{ measurement.title }}:
                                    </span> 
                                    <span class="font-weight-regular">
                                      {{ measurement.answer }}
                                    </span>
                                  </div>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                    </v-tabs-items>
                  </div>
                </v-card-text>

                <v-card-actions
                  v-if="register"
                  style="flex-wrap: wrap; justify-content: flex-end;"
                >
                  <v-spacer />
                  <v-btn
                    color="default"
                    @click="cancelRegister"
                    style="margin-bottom: 5px;"
                  >
                    {{ str['cancel'] }}
                  </v-btn>
                  <v-btn
                    v-if="!register.paym_sub || (register.paym_sub && register.paym_sub_status !== 0 && register.paym_sub_status !== registerPaymentActiveValue)"
                    color="error"
                    @click="refuseClient"
                    style="margin-bottom: 5px;"
                  >
                    {{ str['decline_client'] }}
                  </v-btn>
                  <v-btn
                    v-if="showAdminSubsCheck && (!register.paym_sub || (register.paym_sub && register.paym_sub_status !== 0 && register.paym_sub_status !== registerPaymentActiveValue))"
                    color="secondary"
                    @click="validatePaymentClient"
                    style="margin-bottom: 5px;"
                  >
                    {{ str['validate_payment'] }}
                  </v-btn>
                  <v-btn
                    color="success"
                    @click="validateClient"
                    style="margin-bottom: 5px;"
                  >
                    {{ str['validate_client'] }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogPayday"
              persistent
              max-width="500px"
            >
              <v-card>
                <v-card-title>
                  <span class="headline">
                    {{ str['select_payment_date'] }}
                  </span>
                </v-card-title>

                <v-card-text>
                  <v-menu
                    v-model="datepickerMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="payday"
                        :label="str['payment_date']"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="payday"
                      style="margin: 0;"
                      @change="datepickerMenu = false"
                      :locale="datepickerLanguage"
                    />
                  </v-menu>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="default"
                    @click="dialogPayday = false"
                  >
                    {{ str['cancel'] }}
                  </v-btn>
                  <v-btn
                    color="success"
                    @click="confirmValidateClient(payday)"
                  >
                    {{ str['confirm'] }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-toolbar
              v-if="(showReturnFeedbacks && hasReturnFeedbacks) || (showNewPlansFeedbacks && hasNewPlansFeedbacks)"
              flat
            >
              <v-tabs
                v-model="registersFeedbacksTableTabActive"
                background-color="transparent"
                color="secondary"
                style="margin-top: 0;"
                grow
                show-arrows
              >
                <v-tab
                  v-for="(fbtab, fbtabindex) in registersFeedbackTypes"
                  :key="fbtabindex"
                >
                  {{ str[fbtab.name] ? str[fbtab.name] : fbtab.name }}
                </v-tab>
              </v-tabs>
              <v-spacer />
            </v-toolbar>
            <v-card-text v-if="(showReturnFeedbacks && hasReturnFeedbacks) || (showNewPlansFeedbacks && hasNewPlansFeedbacks)">
              <v-data-table
                :headers="feedbacksHeaders"
                :items="registersFeedbacksFiltered"
                :items-per-page="tableItemsPerPage['feedbacks']"
                :search="registersFeedbacksSearch"
                sort-by="date"
                :no-data-text="tablesLoaded.indexOf('feedbacks') === -1 ? str['processing'] : str['feedbacks_received_empty']"
                :no-results-text="tablesLoaded.indexOf('feedbacks') === -1 ? str['processing'] : str['feedbacks_received_empty']"
                @click:row="openPhysicalEvaluation"
                @update:items-per-page="function(itemsPerPageValue) {updateTableItemsPerPage('feedbacks', itemsPerPageValue)}"
              >
                <template v-slot:top>
                  <v-toolbar
                    flat
                  >
                    <div class="row-flex-align-space row-full-width">
                      <div
                        class="mr-4"
                        style="width: 100%; max-width: 500px;"
                      >
                        <v-text-field
                          v-model="registersFeedbacksSearch"
                          append-icon="mdi-magnify"
                          :label="str['search']"
                          single-line
                          hide-details
                        />
                      </div>
                      <div class="datepicker-element">
                        <v-menu
                          v-model="registersFeedbacksDatepickerMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="registersFeedbacksFilterDatesText"
                              :label="str['interval_dates']"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              hide-details
                            />
                          </template>
                          <v-date-picker
                            v-model="registersFeedbacksFilterDates"
                            range
                            style="margin: 0;"
                            @change="registersFeedbacksDatepickerMenu = false"
                            :locale="datepickerLanguage"
                          />
                        </v-menu>
                      </div>
                    </div>
                    <v-spacer />
                  </v-toolbar>
                </template>
                <template v-slot:item.user_id="{ item }">
                  {{ item.user_id }}
                </template>
                <template v-slot:item.date="{ item }">
                  {{ convertUtcDate(item.date) }}
                </template>
                <template v-slot:item.status="{ item }">
                  <span v-bind:class="(physicalEvaluationStatusDict[item.status] ? physicalEvaluationStatusDict[item.status].type : 'default') + '--text'">
                    {{ physicalEvaluationStatusDict[item.status] ? physicalEvaluationStatusDict[item.status].label : str['n_a'] }}
                  </span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    v-if="showChatButton"
                    small
                    class="mr-0"
                    color="secondary"
                    @click.stop="openClientChat(item)"
                  >
                    mdi-chat
                  </v-icon>
                </template>
                <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                  {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col
        v-if="feedbacksVisible"
        :cols="12"
        :lg="tablesCols"
        :md="tablesCols"
        :sm="12"
        :style="{'order': orderTables && tableOrder['feedbacks'] ? tableOrder['feedbacks'] : null}"
      >
        <base-material-card
          color="secondary"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              {{ tablesNames && tablesNames['feedbacks'] ? tablesNames['feedbacks'] : str['feedbacks_received'] }}
            </div>
          </template>
          <v-toolbar
            v-if="(showWeightVariationAlert && hasWeightVariationAlertFeedbacks) || showInProgressFeedbacks || showFeedbacksTabs"
            flat
          >
            <v-tabs
              v-model="feedbacksTableTabActive"
              background-color="transparent"
              color="secondary"
              style="margin-top: 0;"
              grow
              show-arrows
            >
              <v-tab
                v-for="(fbtab, fbtabindex) in feedbackTypes"
                :key="fbtabindex"
                v-show="fbtab.status !== 'weight_variation_alert' || (fbtab.status === 'weight_variation_alert' && showWeightVariationAlert && hasWeightVariationAlertFeedbacks)"
              >
                {{ str[fbtab.name] ? str[fbtab.name] : fbtab.name }}
                <span
                  v-if="showFeedbacksTabs && feedbacksTabsCounter && feedbacksTabsCounter[fbtab.id]"
                  class="warning--text pl-1"
                >
                  ({{ feedbacksTabsCounter[fbtab.id] }})
                </span>
              </v-tab>
            </v-tabs>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-data-table
              :headers="feedbacksHeaders"
              :items="feedbacksFiltered"
              :items-per-page="tableItemsPerPage['feedbacks']"
              :search="feedbacksSearch"
              sort-by="date"
              :no-data-text="tablesLoaded.indexOf('feedbacks') === -1 ? str['processing'] : str['feedbacks_received_empty']"
              :no-results-text="tablesLoaded.indexOf('feedbacks') === -1 ? str['processing'] : str['feedbacks_received_empty']"
              @click:row="openPhysicalEvaluation"
              @update:items-per-page="function(itemsPerPageValue) {updateTableItemsPerPage('feedbacks', itemsPerPageValue)}"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <div class="row-flex-align-space row-full-width">
                    <div
                      class="mr-4"
                      :class="{'row-flex-align': showFeedbackEmployees}"
                      style="width: 100%; max-width: 500px;"
                    >
                      <v-text-field
                        v-if="!showFeedbackEmployees"
                        v-model="feedbacksSearch"
                        append-icon="mdi-magnify"
                        :label="str['search']"
                        single-line
                        hide-details
                      />
                      <div
                        v-if="showFeedbackEmployees"
                        class="row-full-width"
                      >
                        <v-text-field
                          v-model="feedbacksSearch"
                          append-icon="mdi-magnify"
                          :label="str['search']"
                          single-line
                          hide-details
                        />
                      </div>
                      <div
                        v-if="showFeedbackEmployees"
                        class="pl-4 row-full-width"
                      >
                        <v-autocomplete
                          v-model="feedbacksEmployeeFilter"
                          :label="str['employee']"
                          item-text="name"
                          item-value="id"
                          :items="employeesData"
                          :no-data-text="str['no_data']"
                          clearable
                          hide-details
                        />
                      </div>
                    </div>
                    <div class="datepicker-element">
                      <v-menu
                        v-model="feedbacksDatepickerMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="feedbacksFilterDatesText"
                            :label="str['interval_dates']"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            hide-details
                          />
                        </template>
                        <v-date-picker
                          v-model="feedbacksFilterDates"
                          range
                          style="margin: 0;"
                          @change="feedbacksDatepickerMenu = false"
                          :locale="datepickerLanguage"
                        />
                      </v-menu>
                    </div>
                  </div>
                  <v-spacer />
                </v-toolbar>
              </template>
              <template v-slot:item.user_id="{ item }">
                <span v-if="feedbackAlert === 'change_plans' && item.pe_id % 2 !== 0">
                  <i class="mdi mdi-update" />
                </span>
                <span
                  v-if="status2Enabled && item.status2"
                  class="cursor-hover"
                  @click.stop="openClientStatus2Warning(item)"
                >
                  <i
                    :style="{color: item.status2}"
                    class="mdi mdi-alert-circle"
                  />
                </span>
                {{ item.user_id }}
              </template>
              <template v-slot:item.date="{ item }">
                {{ convertUtcDate(item.date) }}
              </template>
              <template v-slot:item.status="{ item }">
                <span v-bind:class="(physicalEvaluationStatusDict[item.status] ? physicalEvaluationStatusDict[item.status].type : 'default') + '--text'">
                  {{ physicalEvaluationStatusDict[item.status] ? physicalEvaluationStatusDict[item.status].label : str['n_a'] }}
                </span>
              </template>
              <template v-slot:item.type="{ item }">
                {{ feedbackTypesDict[item.type] ? feedbackTypesDict[item.type] : item.type }}
              </template>
              <template v-slot:item.employees="{ item }">
                <div v-if="item.employees">
                  <span
                    v-if="!item.employees.length"
                  >
                    -
                  </span>
                  <span
                    v-for="(employeeId, employeeIndex) in item.employees"
                    :key="employeeId"
                  >
                    {{ (employeeIndex > 0 ? ' | ' : '') + (employeesDict[employeeId] ? employeesDict[employeeId] : '-') }}
                  </span>
                </div>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  v-if="showChatButton"
                  small
                  class="mr-0"
                  color="secondary"
                  @click.stop="openClientChat(item)"
                >
                  mdi-chat
                </v-icon>
              </template>
              <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col
        v-if="nextFeedbacksVisible"
        :cols="12"
        :lg="tablesCols"
        :md="tablesCols"
        :sm="12"
        :style="{'order': orderTables && tableOrder['next_feedbacks'] ? tableOrder['next_feedbacks'] : null}"
      >
        <base-material-card
          color="secondary"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              {{ tablesNames && tablesNames['next_feedbacks'] ? tablesNames['next_feedbacks'] : str['next_physical_evaluations'] }}
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="nextFeedbacksHeaders"
              :items="nextFeedbacks"
              :items-per-page="tableItemsPerPage['next_feedbacks']"
              :search="nextFeedbacksSearch"
              :no-data-text="tablesLoaded.indexOf('next_feedbacks') === -1 ? str['processing'] : str['no_data']"
              :no-results-text="tablesLoaded.indexOf('next_feedbacks') === -1 ? str['processing'] : str['no_data']"
              @click:row="openClient"
              @update:items-per-page="function(itemsPerPageValue) {updateTableItemsPerPage('next_feedbacks', itemsPerPageValue)}"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <div class="row-flex-align-space row-full-width">
                    <div
                      :class="{'row-flex-align mr-4': showNextFeedbacksDate}"
                      style="width: 100%; max-width: 500px;"
                    >
                      <v-text-field
                        v-model="nextFeedbacksSearch"
                        append-icon="mdi-magnify"
                        :label="str['search']"
                        single-line
                        hide-details
                      />
                    </div>
                    <div
                      v-if="showNextFeedbacksDate"
                      class="datepicker-element"
                    >
                      <v-menu
                        v-model="nextFeedbacksDatepickerMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="nextFeedbacksDate"
                            :label="str['date']"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            hide-details
                          />
                        </template>
                        <v-date-picker
                          v-model="nextFeedbacksDate"
                          style="margin: 0;"
                          @change="getAlerts({next_feedbacks: true}); nextFeedbacksDatepickerMenu = false;"
                          :locale="datepickerLanguage"
                        />
                      </v-menu>
                    </div>
                  </div>
                  <v-spacer />
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  v-if="showChatButton"
                  small
                  class="mr-0"
                  color="secondary"
                  @click.stop="openClientChat(item)"
                >
                  mdi-chat
                </v-icon>
              </template>
              <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col
        v-if="pendingFeedbacksVisible"
        :cols="12"
        :lg="tablesCols"
        :md="tablesCols"
        :sm="12"
        :style="{'order': orderTables && tableOrder['pending_feedbacks'] ? tableOrder['pending_feedbacks'] : null}"
      >
        <base-material-card
          color="secondary"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              {{ tablesNames && tablesNames['pending_feedbacks'] ? tablesNames['pending_feedbacks'] : str['pending_physical_evaluations'] }}
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="pendingFeedbacksHeaders"
              :items="pendingFeedbacks"
              :items-per-page="tableItemsPerPage['pending_feedbacks']"
              :search="pendingFeedbacksSearch"
              :no-data-text="tablesLoaded.indexOf('pending_feedbacks') === -1 ? str['processing'] : str['no_data']"
              :no-results-text="tablesLoaded.indexOf('pending_feedbacks') === -1 ? str['processing'] : str['no_data']"
              @click:row="openClient"
              @update:items-per-page="function(itemsPerPageValue) {updateTableItemsPerPage('pending_feedbacks', itemsPerPageValue)}"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-text-field
                    v-model="pendingFeedbacksSearch"
                    append-icon="mdi-magnify"
                    :label="str['search']"
                    single-line
                    hide-details
                  />
                  <v-spacer />
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  v-if="showChatButton"
                  small
                  class="mr-0"
                  color="secondary"
                  @click.stop="openClientChat(item)"
                >
                  mdi-chat
                </v-icon>
                <v-icon
                  v-if="showRemovePendingFeedbackAlert"
                  small
                  class="mr-0"
                  color="success"
                  @click.stop="removePendingFeedbackAlert(item)"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col
        v-if="pendingPaymentsVisible"
        :cols="12"
        :lg="tablesCols"
        :md="tablesCols"
        :sm="12"
        :style="{'order': orderTables && tableOrder['pending_payments'] ? tableOrder['pending_payments'] : null}"
      >
        <base-material-card
          color="secondary"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              {{ tablesNames && tablesNames['pending_payments'] ? tablesNames['pending_payments'] : str['pending_clients_payments'] }}
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="pendingPaymentsHeaders"
              :items="pendingPayments"
              :items-per-page="tableItemsPerPage['pending_payments']"
              :search="pendingPaymentsSearch"
              :no-data-text="tablesLoaded.indexOf('pending_payments') === -1 ? str['processing'] : str['pending_clients_payments_empty']"
              :no-results-text="tablesLoaded.indexOf('pending_payments') === -1 ? str['processing'] : str['pending_clients_payments_empty']"
              @click:row="openClient"
              @update:items-per-page="function(itemsPerPageValue) {updateTableItemsPerPage('pending_payments', itemsPerPageValue)}"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-text-field
                    v-model="pendingPaymentsSearch"
                    append-icon="mdi-magnify"
                    :label="str['search']"
                    single-line
                    hide-details
                  />
                  <v-spacer />
                </v-toolbar>
              </template>
              <template v-slot:item.id="{ item }">
                <span
                  v-if="status2Enabled && item.status2"
                  class="cursor-hover"
                  @click.stop="openClientStatus2Warning(item)"
                >
                  <i
                    :style="{color: item.status2}"
                    class="mdi mdi-alert-circle"
                  />
                </span>
                {{ item.id }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  v-if="showChatButton"
                  small
                  class="mr-0"
                  color="secondary"
                  @click.stop="openClientChat(item)"
                >
                  mdi-chat
                </v-icon>
              </template>
              <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col
        v-if="receivedPaymentsVisible"
        :cols="12"
        :lg="tablesCols"
        :md="tablesCols"
        :sm="12"
        :style="{'order': orderTables && tableOrder['received_payments'] ? tableOrder['received_payments'] : null}"
      >
        <base-material-card
          color="secondary"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              {{ tablesNames && tablesNames['received_payments'] ? tablesNames['received_payments'] : str['received_payments'] }}
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="receivedPaymentsHeaders"
              :items="receivedPayments"
              :items-per-page="tableItemsPerPage['received_payments']"
              :search="receivedPaymentsSearch"
              :no-data-text="tablesLoaded.indexOf('received_payments') === -1 ? str['processing'] : str['payments_empty']"
              :no-results-text="tablesLoaded.indexOf('received_payments') === -1 ? str['processing'] : str['payments_empty']"
              @click:row="openPayment"
              @update:items-per-page="function(itemsPerPageValue) {updateTableItemsPerPage('received_payments', itemsPerPageValue)}"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-text-field
                    v-model="receivedPaymentsSearch"
                    append-icon="mdi-magnify"
                    :label="str['search']"
                    single-line
                    hide-details
                  />
                  <v-spacer />
                </v-toolbar>
              </template>
              <template v-slot:item.id="{ item }">
                <span
                  v-if="status2Enabled && item.status2"
                  class="cursor-hover"
                  @click.stop="openClientStatus2Warning(item)"
                >
                  <i
                    :style="{color: item.status2}"
                    class="mdi mdi-alert-circle"
                  />
                </span>
                {{ item.id }}
              </template>
              <template v-slot:item.date="{ item }">
                {{ convertUtcDate(item.date) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  v-if="showChatButton"
                  small
                  class="mr-0"
                  color="secondary"
                  @click.stop="openClientChat(item)"
                >
                  mdi-chat
                </v-icon>
              </template>
              <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col
        v-if="trngPlansVisible"
        :cols="12"
        :lg="tablesCols"
        :md="tablesCols"
        :sm="12"
        :style="{'order': orderTables && tableOrder['trng_plans'] ? tableOrder['trng_plans'] : null}"
      >
        <base-material-card
          color="secondary"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              {{ tablesNames && tablesNames['trng_plans'] ? tablesNames['trng_plans'] : str['expiring_training_plans'] }}
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="trngPlansHeaders"
              :items="trngPlans"
              :items-per-page="tableItemsPerPage['trng_plans']"
              :search="trngPlansSearch"
              :no-data-text="tablesLoaded.indexOf('trng_plans') === -1 ? str['processing'] : str['expiring_training_plans_empty']"
              :no-results-text="tablesLoaded.indexOf('trng_plans') === -1 ? str['processing'] : str['expiring_training_plans_empty']"
              @click:row="openClient"
              @update:items-per-page="function(itemsPerPageValue) {updateTableItemsPerPage('trng_plans', itemsPerPageValue)}"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-text-field
                    v-model="trngPlansSearch"
                    append-icon="mdi-magnify"
                    :label="str['search']"
                    single-line
                    hide-details
                  />
                  <v-spacer />
                </v-toolbar>
              </template>
              <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col
        v-if="phyEvalsVisible"
        :cols="12"
        :lg="tablesCols"
        :md="tablesCols"
        :sm="12"
        :style="{'order': orderTables && tableOrder['phy_evals'] ? tableOrder['phy_evals'] : null}"
      >
        <base-material-card
          color="secondary"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              {{ tablesNames && tablesNames['phy_evals'] ? tablesNames['phy_evals'] : str['next_physical_evaluations'] }}
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="phyEvalsHeaders"
              :items="phyEvals"
              :items-per-page="tableItemsPerPage['phy_evals']"
              :search="phyEvalsSearch"
              :no-data-text="tablesLoaded.indexOf('phy_evals') === -1 ? str['processing'] : str['no_physical_evaluations']"
              :no-results-text="tablesLoaded.indexOf('phy_evals') === -1 ? str['processing'] : str['no_physical_evaluations']"
              @click:row="openClient"
              @update:items-per-page="function(itemsPerPageValue) {updateTableItemsPerPage('phy_evals', itemsPerPageValue)}"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-text-field
                    v-model="phyEvalsSearch"
                    append-icon="mdi-magnify"
                    :label="str['search']"
                    single-line
                    hide-details
                  />
                  <v-spacer />
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  v-if="showChatButton"
                  small
                  class="mr-0"
                  color="secondary"
                  @click.stop="openClientChat(item)"
                >
                  mdi-chat
                </v-icon>
              </template>
              <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col
        v-if="nutriPlansVisible"
        :cols="12"
        :lg="tablesCols"
        :md="tablesCols"
        :sm="12"
        :style="{'order': orderTables && tableOrder['nutri_plans'] ? tableOrder['nutri_plans'] : null}"
      >
        <base-material-card
          color="secondary"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              {{ tablesNames && tablesNames['nutri_plans'] ? tablesNames['nutri_plans'] : str['nutrition_plans_to_expire'] }}
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="nutriPlansHeaders"
              :items="nutriPlans"
              :items-per-page="tableItemsPerPage['nutri_plans']"
              :search="nutriPlansSearch"
              :no-data-text="tablesLoaded.indexOf('nutri_plans') === -1 ? str['processing'] : str['no_data']"
              :no-results-text="tablesLoaded.indexOf('nutri_plans') === -1 ? str['processing'] : str['no_data']"
              @click:row="openClient"
              @update:items-per-page="function(itemsPerPageValue) {updateTableItemsPerPage('nutri_plans', itemsPerPageValue)}"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-text-field
                    v-model="nutriPlansSearch"
                    append-icon="mdi-magnify"
                    :label="str['search']"
                    single-line
                    hide-details
                  />
                  <v-spacer />
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  v-if="showChatButton"
                  small
                  class="mr-0"
                  color="secondary"
                  @click.stop="openClientChat(item)"
                >
                  mdi-chat
                </v-icon>
              </template>
              <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col
        v-if="paymentProofsVisible"
        :cols="12"
        :lg="tablesCols"
        :md="tablesCols"
        :sm="12"
        :style="{'order': orderTables && tableOrder['payment_proofs'] ? tableOrder['payment_proofs'] : null}"
      >
        <base-material-card
          color="secondary"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              {{ tablesNames && tablesNames['payment_proofs'] ? tablesNames['payment_proofs'] : str['payment_proofs'] }}
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="paymentProofsHeaders"
              :items="paymentProofs"
              :items-per-page="tableItemsPerPage['payment_proofs']"
              :search="paymentProofsSearch"
              :no-data-text="tablesLoaded.indexOf('payment_proofs') === -1 ? str['processing'] : str['payments_empty']"
              :no-results-text="tablesLoaded.indexOf('payment_proofs') === -1 ? str['processing'] : str['payments_empty']"
              @click:row="openPayment"
              @update:items-per-page="function(itemsPerPageValue) {updateTableItemsPerPage('payment_proofs', itemsPerPageValue)}"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-text-field
                    v-model="paymentProofsSearch"
                    append-icon="mdi-magnify"
                    :label="str['search']"
                    single-line
                    hide-details
                  />
                  <v-spacer />
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  v-if="showChatButton"
                  small
                  class="mr-0"
                  color="secondary"
                  @click.stop="openClientChat(item)"
                >
                  mdi-chat
                </v-icon>
              </template>
              <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col
        v-if="nextPaymentsVisible"
        :cols="12"
        :lg="tablesCols"
        :md="tablesCols"
        :sm="12"
        :style="{'order': orderTables && tableOrder['next_payments'] ? tableOrder['next_payments'] : null}"
      >
        <base-material-card
          color="secondary"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              {{ tablesNames && tablesNames['next_payments'] ? tablesNames['next_payments'] : str['next_payments'] }}
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="nextPaymentsHeaders"
              :items="nextPayments"
              :items-per-page="tableItemsPerPage['next_payments']"
              :search="nextPaymentsSearch"
              :no-data-text="tablesLoaded.indexOf('next_payments') === -1 ? str['processing'] : str['payments_empty']"
              :no-results-text="tablesLoaded.indexOf('next_payments') === -1 ? str['processing'] : str['payments_empty']"
              @click:row="openPayment"
              @update:items-per-page="function(itemsPerPageValue) {updateTableItemsPerPage('next_payments', itemsPerPageValue)}"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <div class="row-flex-align-space row-full-width">
                    <div
                      class="row-flex-align mr-4"
                      style="width: 100%; max-width: 500px;"
                    >
                      <v-text-field
                        v-model="nextPaymentsSearch"
                        append-icon="mdi-magnify"
                        :label="str['search']"
                        single-line
                        hide-details
                      />
                    </div>
                    <div
                      class="datepicker-element"
                    >
                      <v-menu
                        v-model="nextPaymentsDatepickerMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="nextPaymentsDate"
                            :label="str['date']"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            hide-details
                          />
                        </template>
                        <v-date-picker
                          v-model="nextPaymentsDate"
                          style="margin: 0;"
                          @change="getAlerts({next_payments: true}); nextPaymentsDatepickerMenu = false;"
                          :locale="datepickerLanguage"
                        />
                      </v-menu>
                    </div>
                  </div>
                  <v-spacer />
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  v-if="showChatButton"
                  small
                  class="mr-0"
                  color="secondary"
                  @click.stop="openClientChat(item)"
                >
                  mdi-chat
                </v-icon>
              </template>
              <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-if="showTotalValues"
        cols="12"
        class="mb-8"
      >
        <v-row>
          <v-col
            cols="12"
            lg="3"
            md="6"
            sm="6"
            class="pt-dashboard-total-box"
          >
            <base-material-stats-card
              color="green lighten-2"
              icon="mdi-account-check"
              :title="str['active_clients']"
              :value="activeClients"
            />
          </v-col>
          <v-col
            cols="12"
            lg="3"
            md="6"
            sm="6"
            class="pt-dashboard-total-box"
          >
            <base-material-stats-card
              color="orange lighten-2"
              icon="mdi-account-lock"
              :title="str['suspended_clients']"
              :value="suspendedClients"
            />
          </v-col>
          <v-col
            cols="12"
            lg="3"
            md="6"
            sm="6"
            class="pt-dashboard-total-box"
          >
            <base-material-stats-card
              color="red lighten-2"
              icon="mdi-account-cancel"
              :title="str['blocked_clients']"
              :value="blockedClients"
            />
          </v-col>
          <v-col
            cols="12"
            lg="3"
            md="6"
            sm="6"
            class="pt-dashboard-total-box"
          >
            <base-material-stats-card
              color="teal lighten-2"
              icon="mdi-account-multiple-plus"
              :title="str['total_registers']"
              :value="registersTotal"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog
      v-if="ptPayment"
      v-model="dialogPtPaymentRemarks"
      persistent
      max-width="650px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['monthly_payment'] }} {{ ptPayment.month_year }}
          </span>
        </v-card-title>
        <v-col
          cols="12"
          style="max-width: 450px; margin: 0 auto;"
        >
          <div
            style="font-weight: bold; font-size: 40px;"
            class="text-center mb-2"
          >
            {{ ptPayment.amount }}€
          </div>
          <div
            v-if="ptPayment && ptPayment.remarks"
            style="white-space: pre-line;"
            class="mb-3"
          >
            <div
              style="font-weight: bold;"
              class="mb-1"
            >
              {{ str['details'] }}
            </div>
            <div>
              {{ ptPayment.remarks }}
            </div>
          </div>
          <div class="pt-payment-ways">
            <div
              v-if="ptPayment && ptPayment.iban"
              class="pt-4"
            >
              <img
                :src="require('@/assets/icon-bank-transfer.png')"
                style="height: 40px;"
              />
              <div
                class="row-flex-align cursor-hover"
                @click="copyTextToClipboard(ptPayment.iban)"
              >
                <div>
                  {{ ptPayment.iban }}
                </div>
                <i
                  class="mdi mdi-content-copy"
                  style="margin-left: 20px;"
                />
              </div>
            </div>
            <div
              v-if="ptPayment && ptPayment.wise"
              class="row-flex-align pt-4"
            >
              <img
                :src="require('@/assets/icon-wise.png')"
                style="height: 60px;"
              />
              <div class="row-flex-align cursor-hover">
                <a
                  :href="ptPayment.wise"
                  target="_blank"
                  class="pt-payment-ways-link"
                >
                  {{ ptPayment.wise }}
                </a>
                <i
                  class="mdi mdi-content-copy"
                  style="margin-left: 20px;"
                  @click="copyTextToClipboard(ptPayment.wise)"
                />
              </div>
            </div>
          </div>
        </v-col>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="dialogPtPaymentRemarks = false"
          >
            {{ str['close'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="assignEmployeesData"
      v-model="dialogAssignEmployees"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['assing_employee'] }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-autocomplete
              v-model="assignEmployeesData.employee1"
              :label="str['employee']"
              item-text="name"
              item-value="id"
              :items="employeesList"
              :no-data-text="str['no_data']"
            />
            <v-autocomplete
              v-if="!assignOnlyOneEmployee"
              v-model="assignEmployeesData.employee2"
              :label="str['employee']"
              item-text="name"
              item-value="id"
              :items="employeesList"
              :no-data-text="str['no_data']"
            />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="closeAssignEmployees"
          >
            {{ str['cancel'] }}
          </v-btn>
          <v-btn
            color="success"
            @click="confirmAssignEmployees()"
          >
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style>
  .pt-payment-container {
    display: flex;
    justify-content: flex-end;
  }

  .pt-payment-container>div {
    max-width: 200px;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    font-weight: bold;
  }

  .pt-payment-container>div>div:first-child {
    padding-right: 15px;
  }

  .pt-payment-container i {
    font-size: 20px;
    cursor: pointer;
  }

  .pt-payment-container i:hover {
    opacity: 0.7;
  }

  .pt-payment-ways>div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 10px;
  }

  .pt-payment-ways img {
    margin-right: 15px;
  }

  .pt-payment-ways>div>div {
    font-weight: bold;
    font-size: 18px;
  }

  .pt-payment-ways-link {
    color: #0d6efd !important;
  }

  .pt-payment-ways-link:hover {
    opacity: 0.6;
  }

  .pt-dashboard-total-box .v-card--material__heading {
    min-height: 50px;
    height: 50px;
    max-height: 50px;
    min-width: 50px;
    width: 50px;
    max-width: 50px;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon-photo-download {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    font-size: 1.5rem;
    cursor: pointer;
    color: #888;
    z-index: 99;
  }

  .icon-photo-download i:hover {
    opacity: 0.6;
  }

  .icon-photo-delete {
    position: absolute;
    top: 0;
    right: 40px;
    padding: 10px;
    font-size: 1.5rem;
    cursor: pointer;
    color: #ff5252;
    z-index: 99;
  }

  .icon-photo-delete i:hover {
    opacity: 0.6;
  }
</style>

<script>
  import Api from '@/services/Api'
  import ChatApi from '@/services/ChatApi'
  import StorageApi from '@/services/StorageApi'
  import Utils from '@/services/Utils'
  import axios from 'axios'
  import moment from 'moment'

  export default {
    name: 'Dashboard',

    data() {
      const user = Utils.getUser()
      const defaultTables = [
        'registers',
        'initial_quizs',
        'pending_payments',
        'received_payments',
        'trng_plans',
        'phy_evals',
        'nutri_plans',
        'feedbacks',
        'payment_proofs',
        'next_payments',
        'birthdays',
        'next_feedbacks',
        'pending_feedbacks',
      ]
      const tableOrder = {}
      const tables = []
      for (let i = 0; i < defaultTables.length; i++) {
        const tableIndex = user && user.configurations && user.configurations.dashboard ? user.configurations.dashboard.indexOf(defaultTables[i]) : -1
        if (tableIndex > -1) {
          tableOrder[defaultTables[i]] = tableIndex + 1
          tables.push(defaultTables[i])
        }
      }
      const defaultRegisterTabs = [{
        id: 'details',
        name: window.strings['general_details'],
      }, {
        id: 'questions',
        name: window.strings['questions'],
      }, {
        id: 'photos',
        name: window.strings['photos_abbr'],
      }, {
        id: 'videos',
        name: window.strings['videos'],
      }, {
        id: 'measurements',
        name: window.strings['measurements'],
      }, {
        id: 'payment',
        name: window.strings['payment'],
      }]
      const registerTabs = []
      const showDetailsQuestionsSameTab = user && user.configurations && user.configurations.register && user.configurations.register.join_details_questions ? true : false
      for (let i = 0; i < defaultRegisterTabs.length; i++) {
        if (user && user.configurations && user.configurations.registers &&
          user.configurations.registers.indexOf(defaultRegisterTabs[i].id) > -1 &&
          (defaultRegisterTabs[i].id !== 'questions' || (defaultRegisterTabs[i].id === 'questions' && !showDetailsQuestionsSameTab))
        ) {
          registerTabs.push({
            id: defaultRegisterTabs[i].id,
            name: defaultRegisterTabs[i].name,
          })
        }
      }

      const showRegisters = tables.indexOf('registers') > -1 && Utils.hasPermission('registers_valid')
      const showInitialQuiz = tables.indexOf('initial_quizs') > -1 && Utils.hasPermission('feedbacks_view')
      const showPendingPayments = tables.indexOf('pending_payments') > -1 && Utils.hasPermission('pending_payments_view')
      const showReceivedPayments = tables.indexOf('received_payments') > -1 && Utils.hasPermission('received_payments_view')
      const showTrainingPlans = tables.indexOf('trng_plans') > -1 && Utils.hasPermission('trng_plans_view')
      const showPhysicalEvaluations = tables.indexOf('phy_evals') > -1 && Utils.hasPermission('feedbacks_view')
      const showNutriPlans = tables.indexOf('nutri_plans') > -1 && Utils.hasPermission('nutrition_view')
      const showFeedbacks = tables.indexOf('feedbacks') > -1 && Utils.hasPermission('feedbacks_view')
      const showPaymentProofs = tables.indexOf('payment_proofs') > -1 && Utils.hasPermission('payments_valid')
      const showNextPayments = tables.indexOf('next_payments') > -1 && Utils.hasPermission('next_payments')
      const showBirthdays = tables.indexOf('birthdays') > -1 && Utils.hasPermission('clients_birthdays')
      const showNextFeedbacks = tables.indexOf('next_feedbacks') > -1 && Utils.hasPermission('feedbacks_view')
      const showPendingFeedbacks = tables.indexOf('pending_feedbacks') > -1 && Utils.hasPermission('feedbacks_view')

      let showChatButton = false
      if (user && user.configurations && user.configurations.chat && Utils.hasPermission('chat_view')) {
        showChatButton = true
      }

      let showRegisterChatButton = false
      if (user && user.configurations && user.configurations.register && user.configurations.register.show_chat_button) {
        showRegisterChatButton = true
      }

      let showAdminSubsCheck = false
      if (user && user.configurations && user.configurations.admin_subs_check) {
        showAdminSubsCheck = true
      }

      let showCompleteRegistersChatMessageTab = false
      if (user && user.configurations && user.configurations.register && user.configurations.register.show_complete_registers_with_chat_message_tab) {
        showCompleteRegistersChatMessageTab = true
      }

      let showReturnFeedbacks = false
      if (user && user.configurations && user.configurations.clients && user.configurations.clients.show_back_link) {
        showReturnFeedbacks = true
      }

      let showNewPlansFeedbacks = false
      if (user && user.configurations && user.configurations.clients && user.configurations.clients.show_new_plans_link) {
        showNewPlansFeedbacks = true
      }

      let showWeightVariationAlert = false
      if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.show_weight_variation_alert) {
        showWeightVariationAlert = true
      }

      let showRegisterPlan = false
      if (user && user.configurations && user.configurations.payment && user.configurations.payment.show_register_plan) {
        showRegisterPlan = true
      }

      let assignOnlyOneEmployee = false
      if (user && user.configurations && user.configurations.employees && user.configurations.employees.assing_only_one) {
        assignOnlyOneEmployee = true
      }

      let updateRegisterPhotosEnabled = false
      if (user && user.configurations && user.configurations.register && user.configurations.register.update_photos) {
        updateRegisterPhotosEnabled = true
      }

      let addRegisterPaymentEnabled = false
      if (user && user.configurations && user.configurations.register && user.configurations.register.add_payment_proof) {
        addRegisterPaymentEnabled = true
      }

      let addRegisterPaymentFields = false
      if (user && user.configurations && user.configurations.register && user.configurations.register.add_payment_proof_fields) {
        addRegisterPaymentFields = user.configurations.register.add_payment_proof_fields
      }

      let orderTables = false
      if (user && user.configurations && user.configurations.dashboard_config && user.configurations.dashboard_config.order_tables) {
        orderTables = true
      }

      let hideRegisterTabs = false
      if (user && user.configurations && user.configurations.dashboard_config && user.configurations.dashboard_config.hide_register_tabs) {
        hideRegisterTabs = true
      }

      let showInvoice = false
      if (user && user.configurations && user.configurations.payment && user.configurations.payment.invoice) {
        showInvoice = true
      }

      let tablesNames = false
      if (user && user.configurations && user.configurations.dashboard_config && user.configurations.dashboard_config.tables_names) {
        tablesNames = user.configurations.dashboard_config.tables_names
      }

      let showFeedbacksTabs = false
      if (user && user.configurations && user.configurations.dashboard_config && user.configurations.dashboard_config.show_feedbacks_tabs) {
        showFeedbacksTabs = true
      }

      let showRemovePendingFeedbackAlert = false
      if (user && user.configurations && user.configurations.dashboard_config && user.configurations.dashboard_config.show_remove_pending_feedback_alert) {
        showRemovePendingFeedbackAlert = true
      }

      let showFeedbackEmployees = false
      if (user && user.configurations && user.configurations.dashboard_config && user.configurations.dashboard_config.show_feedback_employees) {
        showFeedbackEmployees = true
      }

      let showNextFeedbacksDate = false
      if (user && user.configurations && user.configurations.dashboard_config && user.configurations.dashboard_config.show_next_feedbacks_date) {
        showNextFeedbacksDate = true
      }

      return {
        str: window.strings,
        datepickerLanguage: window.datepickerLanguage,
        user: user,
        isHotmart: window.useHotmart ? true : false,
        convertUtcDate: Utils.convertUtcDate,
        hasPermission: Utils.hasPermission,
        paymentsViewPermission: Utils.hasPermission('payments_view'),
        showChatButton: showChatButton,
        showRegisterChatButton: showRegisterChatButton,
        showAdminSubsCheck: showAdminSubsCheck,
        showCompleteRegistersChatMessageTab: showCompleteRegistersChatMessageTab,
        showReturnFeedbacks: showReturnFeedbacks,
        showNewPlansFeedbacks: showNewPlansFeedbacks,
        showWeightVariationAlert: showWeightVariationAlert,
        showRegisterPlan: showRegisterPlan,
        assignOnlyOneEmployee: assignOnlyOneEmployee,
        updateRegisterPhotosEnabled: updateRegisterPhotosEnabled,
        addRegisterPaymentEnabled: addRegisterPaymentEnabled,
        addRegisterPaymentFields: addRegisterPaymentFields,
        orderTables: orderTables,
        hideRegisterTabs: hideRegisterTabs,
        showInvoice: showInvoice,
        tablesNames: tablesNames,
        showFeedbacksTabs: showFeedbacksTabs,
        showRemovePendingFeedbackAlert: showRemovePendingFeedbackAlert,
        showFeedbackEmployees: showFeedbackEmployees,
        showNextFeedbacksDate: showNextFeedbacksDate,
        tableItemsPerPage: {
          birthdays: window.cacheTableItemsPerPage && window.cacheTableItemsPerPage.dashboard && window.cacheTableItemsPerPage.dashboard.birthdays ? window.cacheTableItemsPerPage.dashboard.birthdays : 5,
          initial_quizs: window.cacheTableItemsPerPage && window.cacheTableItemsPerPage.dashboard && window.cacheTableItemsPerPage.dashboard.initial_quizs ? window.cacheTableItemsPerPage.dashboard.initial_quizs : 5,
          registers: window.cacheTableItemsPerPage && window.cacheTableItemsPerPage.dashboard && window.cacheTableItemsPerPage.dashboard.registers ? window.cacheTableItemsPerPage.dashboard.registers : 5,
          feedbacks: window.cacheTableItemsPerPage && window.cacheTableItemsPerPage.dashboard && window.cacheTableItemsPerPage.dashboard.feedbacks ? window.cacheTableItemsPerPage.dashboard.feedbacks : 5,
          next_feedbacks: window.cacheTableItemsPerPage && window.cacheTableItemsPerPage.dashboard && window.cacheTableItemsPerPage.dashboard.next_feedbacks ? window.cacheTableItemsPerPage.dashboard.next_feedbacks : 5,
          pending_feedbacks: window.cacheTableItemsPerPage && window.cacheTableItemsPerPage.dashboard && window.cacheTableItemsPerPage.dashboard.pending_feedbacks ? window.cacheTableItemsPerPage.dashboard.pending_feedbacks : 5,
          pending_payments: window.cacheTableItemsPerPage && window.cacheTableItemsPerPage.dashboard && window.cacheTableItemsPerPage.dashboard.pending_payments ? window.cacheTableItemsPerPage.dashboard.pending_payments : 5,
          received_payments: window.cacheTableItemsPerPage && window.cacheTableItemsPerPage.dashboard && window.cacheTableItemsPerPage.dashboard.received_payments ? window.cacheTableItemsPerPage.dashboard.received_payments : 5,
          trng_plans: window.cacheTableItemsPerPage && window.cacheTableItemsPerPage.dashboard && window.cacheTableItemsPerPage.dashboard.trng_plans ? window.cacheTableItemsPerPage.dashboard.trng_plans : 5,
          phy_evals: window.cacheTableItemsPerPage && window.cacheTableItemsPerPage.dashboard && window.cacheTableItemsPerPage.dashboard.phy_evals ? window.cacheTableItemsPerPage.dashboard.phy_evals : 5,
          nutri_plans: window.cacheTableItemsPerPage && window.cacheTableItemsPerPage.dashboard && window.cacheTableItemsPerPage.dashboard.nutri_plans ? window.cacheTableItemsPerPage.dashboard.nutri_plans : 5,
          payment_proofs: window.cacheTableItemsPerPage && window.cacheTableItemsPerPage.dashboard && window.cacheTableItemsPerPage.dashboard.payment_proofs ? window.cacheTableItemsPerPage.dashboard.payment_proofs : 5,
          next_payments: window.cacheTableItemsPerPage && window.cacheTableItemsPerPage.dashboard && window.cacheTableItemsPerPage.dashboard.next_payments ? window.cacheTableItemsPerPage.dashboard.next_payments : 5,
        },
        tableOrder: tableOrder,
        tablesCols: 12,
        registerTabActive: 0,
        registerTabs: registerTabs,
        showDetailsQuestionsSameTab: showDetailsQuestionsSameTab,
        ptPayment: null,
        dialogRegister: false,
        register: null,
        registersVisible: showRegisters,
        registers: [],
        registersHeaders: this.getTableHeaders(user, showChatButton, 'registers'),
        registersTableTabActive: 0,
        showRegistersTableTabs: false,
        registersSearch: '',
        initialQuizFeedbacksVisible: showInitialQuiz,
        initialQuizFeedbacks: [],
        initialQuizFeedbacksHeaders: this.getTableHeaders(user, showChatButton, 'initialQuizFeedbacks'),
        initialQuizFeedbacksSearch: '',
        pendingPaymentsVisible: showPendingPayments,
        pendingPayments: [],
        pendingPaymentsHeaders: this.getTableHeaders(user, showChatButton, 'pendingPayments'),
        pendingPaymentsSearch: '',
        receivedPaymentsVisible: showReceivedPayments,
        receivedPayments: [],
        receivedPaymentsHeaders: this.getTableHeaders(user, showChatButton, 'receivedPayments'),
        receivedPaymentsSearch: '',
        trngPlansVisible: showTrainingPlans,
        trngPlans: [],
        trngPlansHeaders: this.getTableHeaders(user, showChatButton, 'trngPlans'),
        trngPlansSearch: '',
        phyEvalsVisible: showPhysicalEvaluations,
        phyEvals: [],
        phyEvalsHeaders: this.getTableHeaders(user, showChatButton, 'phyEvals'),
        phyEvalsSearch: '',
        nutriPlansVisible: showNutriPlans,
        nutriPlans: [],
        nutriPlansHeaders: this.getTableHeaders(user, showChatButton, 'nutriPlans'),
        nutriPlansSearch: '',
        feedbacksVisible: showFeedbacks,
        feedbackAlert: user && user.configurations && user.configurations.feedback && user.configurations.feedback.feedback_alert ? user.configurations.feedback.feedback_alert : null,
        feedbacks: [],
        feedbacksHeaders: this.getTableHeaders(user, showChatButton, 'feedbacks'),
        feedbackReturnType: this.getReturnFeedbackType(),
        feedbackNewPlansType: this.getNewPlansFeedbackType(),
        feedbackTypes: [],
        feedbackTypesDict: this.getFeedbackTypesDict(user),
        showInProgressFeedbacks: false,
        feedbacksTableTabActive: 0,
        feedbacksSearch: '',
        feedbacksDatepickerMenu: false,
        feedbacksFilterDates: [null, null],
        registersFeedbackTypes: [],
        registersFeedbacksTableTabActive: 0,
        registersFeedbacksSearch: '',
        registersFeedbacksDatepickerMenu: false,
        registersFeedbacksFilterDates: [null, null],
        paymentProofsVisible: showPaymentProofs,
        paymentProofs: [],
        paymentProofsHeaders: this.getTableHeaders(user, showChatButton, 'paymentProofs'),
        paymentProofsSearch: '',
        birthdaysVisible: showBirthdays,
        birthdays: [],
        birthdaysHeaders: this.getTableHeaders(user, showChatButton, 'birthdays'),
        birthdaysSearch: '',
        nextFeedbacksVisible: showNextFeedbacks,
        nextFeedbacks: [],
        nextFeedbacksHeaders: this.getTableHeaders(user, showChatButton, 'nextFeedbacks'),
        nextFeedbacksSearch: '',
        nextFeedbacksDate: moment().format('YYYY-MM-DD'),
        nextFeedbacksDatepickerMenu: false,
        pendingFeedbacksVisible: showPendingFeedbacks,
        pendingFeedbacks: [],
        pendingFeedbacksHeaders: this.getTableHeaders(user, showChatButton, 'pendingFeedbacks'),
        pendingFeedbacksSearch: '',
        nextPaymentsVisible: showNextPayments,
        nextPayments: [],
        nextPaymentsHeaders: this.getTableHeaders(user, showChatButton, 'nextPayments'),
        nextPaymentsSearch: '',
        nextPaymentsDate: moment().format('YYYY-MM-DD'),
        nextPaymentsDatepickerMenu: false,
        activeClients: '0',
        suspendedClients: '0',
        blockedClients: '0',
        registersTotal: '0',
        registerPaymentActiveValues: [4, 98, 99],
        registerPaymentActiveValue: 4,
        registerPaymentProofType: Utils.getPaymentTypeValue('proof'),
        registerPaymentStripeType: Utils.getPaymentTypeValue('stripe'),
        registerPaymentCheckoutType: Utils.getPaymentTypeValue('checkout'),
        registerPaymentHotmartType: Utils.getPaymentTypeValue('hotmart'),
        registerPaymentVWType: Utils.getPaymentTypeValue('viva_wallet'),
        registerPaymentManualType: Utils.getPaymentTypeValue('manual'),
        registerPaymentPromoFreeType: Utils.getPaymentTypeValue('promo_free'),
        registerDetails: user && user.configurations ? this.getRegisterDetails(user.configurations) : [],
        registerQuestions: [],
        ptQuestions: [],
        ptMeasurements: [],
        registerPhotos: [],
        registerVideos: [],
        dialogPayday: false,
        payday: null,
        datepickerMenu: null,
        dialogPtPaymentRemarks: false,
        showTotalValues: Utils.hasPermission('totals_view'),
        currentRegisterPhotoIndex: 0,
        tablesLoaded: [],
        status2Enabled: user && user.configurations && user.configurations.clients && user.configurations.clients.show_status2 ? user.configurations.clients.show_status2 : false,
        registerAssetsProcessing: false,
        foods: {},
        dialogAssignEmployees: false,
        assignEmployeesData: null,
        employeesList: [],
        employeesData: [],
        employeesDict: {},
        physicalEvaluationStatusDict: this.getPhysicalEvaluationStatusDict(),
        imageMaxSize: 700,
        feedbacksEmployeeFilter: null,
        initialQuizEmployeeFilter: null,
      }
    },
    computed: {
      feedbacksFilterDatesText() {
        if (this.feedbacksFilterDates[0] && this.feedbacksFilterDates[1]) {
          return this.feedbacksFilterDates.join(' / ')
        } else {
          return ''
        }
      },
      registersFeedbacksFilterDatesText() {
        if (this.registersFeedbacksFilterDates[0] && this.registersFeedbacksFilterDates[1]) {
          return this.registersFeedbacksFilterDates.join(' / ')
        } else {
          return ''
        }
      },
      feedbacksFiltered() {
        const self = this
        let feedbacks = this.feedbacks
        if (this.showWeightVariationAlert) {
          const tab = self.feedbackTypes[self.feedbacksTableTabActive]
          if (tab) {
            const weightVariationAlertTabActive = tab.status === 'weight_variation_alert' ? true : false
            feedbacks = feedbacks.filter(function (item) {
              if (weightVariationAlertTabActive) {
                return item.weight_variation_alert
              }
              return item.type !== self.feedbackReturnType && item.type !== self.feedbackNewPlansType && !item.weight_variation_alert
            })
          }
        }
        if (this.feedbacksFilterDates[0] && this.feedbacksFilterDates[1]) {
          feedbacks = feedbacks.filter(function (item) {
            return item.date >= self.feedbacksFilterDates[0] + ' 00:00' && item.date <= self.feedbacksFilterDates[1] + ' 23:59'
          })
        }
        if (this.showInProgressFeedbacks) {
          const tab = self.feedbackTypes[self.feedbacksTableTabActive]
          if (tab) {
            feedbacks = feedbacks.filter(function (item) {
              return (self.physicalEvaluationStatusDict[item.status] && tab.status === 'progressing' && self.physicalEvaluationStatusDict[item.status].status === 'progressing') ||
                (!self.physicalEvaluationStatusDict[item.status] || (self.physicalEvaluationStatusDict[item.status] && tab.status !== 'progressing' && self.physicalEvaluationStatusDict[item.status].status !== 'progressing'))
            })
          }
        }
        if (this.showFeedbacksTabs) {
          const tab = self.feedbackTypes[self.feedbacksTableTabActive]
          if (tab) {
            feedbacks = feedbacks.filter(function (item) {
              return item.type === tab.id
            })
          }
        }
        if (this.showFeedbackEmployees && this.feedbacksEmployeeFilter) {
          feedbacks = feedbacks.filter(function (item) {
            return item.employees && item.employees.indexOf(self.feedbacksEmployeeFilter) > -1
          })
        }
        return feedbacks
      },
      initialQuizFeedbacksFiltered() {
        const self = this
        let initialQuizFeedbacks = this.initialQuizFeedbacks
        if (this.showFeedbackEmployees && this.initialQuizEmployeeFilter) {
          initialQuizFeedbacks = initialQuizFeedbacks.filter(function (item) {
            return item.employees && item.employees.indexOf(self.initialQuizEmployeeFilter) > -1
          })
        }
        return initialQuizFeedbacks
      },
      feedbacksTabsCounter() {
        const self = this
        const counter = {}
        if (this.showFeedbacksTabs) {
          this.feedbackTypes.forEach(function (tab) {
            counter[tab.id] = self.feedbacks.filter(function (item) {
              return item.type === tab.id
            }).length
          })
        }
        return counter
      },
      registersFeedbacksFiltered() {
        const self = this
        let feedbacks = this.feedbacks
        if (this.showReturnFeedbacks || this.showNewPlansFeedbacks) {
          const tab = self.registersFeedbackTypes[self.registersFeedbacksTableTabActive]
          if (tab) {
            feedbacks = feedbacks.filter(function (item) {
              return item.type === tab.id
            })
            if (this.registersFeedbacksFilterDates[0] && this.registersFeedbacksFilterDates[1]) {
              feedbacks = feedbacks.filter(function (item) {
                return item.date >= self.registersFeedbacksFilterDates[0] + ' 00:00' && item.date <= self.feedbacksFilterDates[1] + ' 23:59'
              })
            }
            return feedbacks
          }
        }
        return []
      },
      hasReturnFeedbacks() {
        const typeItem = this.registersFeedbackTypes.find(function (type) {
          return type.status === 'return'
        })
        const feeds = this.feedbacks.filter(function (item) {
          return typeItem && item.type === typeItem.id
        })
        return feeds.length ? true : false
      },
      hasNewPlansFeedbacks() {
        const typeItem = this.registersFeedbackTypes.find(function (type) {
          return type.status === 'newplans'
        })
        const feeds = this.feedbacks.filter(function (item) {
          return typeItem && item.type === typeItem.id
        })
        return feeds.length ? true : false
      },
      hasWeightVariationAlertFeedbacks() {
        const feeds = this.feedbacks.filter(function (item) {
          return item.weight_variation_alert
        })
        return feeds.length ? true : false
      },
      registersFiltered() {
        const self = this
        if (!this.showRegistersTableTabs) {
          return this.registers
        }
        if (this.registersTableTabActive === 0) {
          return this.registers.filter(function (item) {
            return self.registerPaymentActiveValues.indexOf(item.paym_sub_status) > -1
          })
        } else {
          if (this.showCompleteRegistersChatMessageTab && this.registersTableTabActive === 1) {
            return this.registers.filter(function (item) {
              return self.registerPaymentActiveValues.indexOf(item.paym_sub_status) > -1 && item.chat_last_msg
            })
          }
          return this.registers.filter(function (item) {
            return self.registerPaymentActiveValues.indexOf(item.paym_sub_status) === -1
          })
        }
      },
    },
    beforeMount: function () {
      const self = this

      if (!this.user) {
        return false
      }

      const alerts = {
        firstCall: {
          totals: true
        },
        lastCall: {}
      }

      // First call
      if (this.registersVisible) {
        alerts.firstCall.registers = true
      }
      if (this.initialQuizFeedbacksVisible) {
        alerts.firstCall.initial_quizs = true
      }
      if (this.feedbacksVisible) {
        alerts.firstCall.feedbacks = true
      }
      if (this.nextFeedbacksVisible) {
        alerts.firstCall.next_feedbacks = true
      }
      if (this.pendingFeedbacksVisible) {
        alerts.firstCall.pending_feedbacks = true
      }
      if (this.phyEvalsVisible) {
        alerts.firstCall.phy_evals = true
      }

      // Last call
      if (this.paymentProofsVisible) {
        alerts.lastCall.payment_proofs = true
      }
      if (this.receivedPaymentsVisible) {
        alerts.lastCall.received_payments = true
      }
      if (this.pendingPaymentsVisible) {
        alerts.lastCall.pending_payments = true
      }
      if (this.trngPlansVisible) {
        alerts.lastCall.trng_plans = true
      }
      if (this.nutriPlansVisible) {
        alerts.lastCall.nutri_plans = true
      }
      if (this.birthdaysVisible) {
        alerts.lastCall.birthdays = true
      }
      if (this.nextPaymentsVisible) {
        alerts.lastCall.next_payments = true
      }

      this.getAlerts(alerts.firstCall, function () {
        self.getAlerts(alerts.lastCall)
      })

      this.subscribeMqttTopic()
      this.validateRegisterParameter()
      this.setFeedbackTypes()
      if (this.showFeedbackEmployees) {
        this.getEmployees()
      }
    },
    methods: {
      getPhysicalEvaluationStatusDict() {
        const dict = {}
        const statusList = Utils.getPhysicalEvaluationStatus()
        if (statusList && statusList.length) {
          statusList.forEach(function (item) {
            dict[item.value] = item
          })
        }
        return dict
      },
      getTableHeaders(user, showChatButton, type) {
        const showRegisterChatButton = user && user.configurations && user.configurations.register && user.configurations.register.show_chat_button ? true : false
        const hideSubscription = user && user.configurations && user.configurations.dashboard_config && user.configurations.dashboard_config.hide_subscription ? true : false
        const showRemovePendingFeedbackAlert = user && user.configurations && user.configurations.dashboard_config && user.configurations.dashboard_config.show_remove_pending_feedback_alert ? true : false
        const showNextFeedbacksDay = user && user.configurations && user.configurations.dashboard_config && user.configurations.dashboard_config.show_next_feedbacks_day ? true : false
        const idFields = ['registers', 'pendingPayments', 'birthdays', 'pendingFeedbacks']
        const excludeDateFields = ['birthdays', 'pendingFeedbacks']
        const headers = [
          { text: window.strings['id'], value: idFields.indexOf(type) > -1 ? 'id' : 'user_id', align: 'center' },
          { text: window.strings['name'], value: 'name', align: 'center' },
        ]
        const sendChatMessage = type === 'registers' || type === 'feedbacks' || type === 'initialQuizFeedbacks' || type === 'pendingPayments' || type === 'receivedPayments' ? true : false

        if (type === 'registers') {
          if (!hideSubscription) {
            headers.push({ text: window.strings['subscription'], value: 'paym_sub_status', align: 'center' })
          }
          headers.push({ text: window.strings['contact'], value: 'mobile_phone', align: 'center' })
          headers.push({ text: window.strings['email'], value: 'email', align: 'center' })
        }

        if (type === 'pendingPayments') {
          headers.push({ text: window.strings['date'], value: 'payday', align: 'center' })
        } else {
          if (excludeDateFields.indexOf(type) === -1) {
            headers.push({ text: window.strings['date'], value: 'date', align: 'center' })
          }
        }

        if ((type === 'nextFeedbacks' && showNextFeedbacksDay) || type === 'nextPayments') {
          headers.push({ text: window.strings['day'], value: 'weekday', align: 'center' })
        }

        if (type === 'pendingFeedbacks') {
          if (showRemovePendingFeedbackAlert) {
            headers.push({ text: window.strings['contact'], value: 'mobile_phone', align: 'center' })
          } else {
            headers.push({ text: window.strings['day'], value: 'feedback_day', align: 'center' })
          }
        }

        if (type === 'feedbacks') {
          if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.tabs && (!user.configurations.dashboard_config || (user.configurations.dashboard_config && !user.configurations.dashboard_config.show_feedbacks_tabs))) {
            headers.push({ text: window.strings['type'], value: 'type', align: 'center' })
          }
          if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.show_employee_name) {
            headers.push({ text: window.strings['employee'], value: 'employee_name', align: 'center' })
          }
          if (user && user.configurations && user.configurations.feedback && user.configurations.feedback.show_status) {
            headers.push({ text: window.strings['status'], value: 'status', align: 'center' })
          }
        }

        if ((type === 'feedbacks' || type === 'initialQuizFeedbacks') && user && user.configurations && user.configurations.dashboard_config && user.configurations.dashboard_config.show_feedback_employees) {
          headers.push({ text: window.strings['employee'], value: 'employees', align: 'center' })
        }

        if ((showChatButton && sendChatMessage && ((showRegisterChatButton && type === 'registers') || type !== 'registers')) || (showRemovePendingFeedbackAlert && type === 'pendingFeedbacks')) {
          headers.push({ text: window.strings['actions'], value: 'actions', align: 'center' })
        }

        return headers
      },
      setFeedbackTypes() {
        this.feedbackTypes = this.getFeedbackTypes(false)
        this.registersFeedbackTypes = this.getFeedbackTypes(true)
      },
      getFeedbackTypes(onlyRegisterTypes) {
        const self = this
        const items = Utils.getFeedbackTypes()
        if (items) {
          if (onlyRegisterTypes) {
            return items.filter(function (item) {
              return (item.status === 'return' && self.showReturnFeedbacks) || (item.status === 'newplans' && self.showNewPlansFeedbacks)
            })
          } else {
            return items.filter(function (item) {
              return item.status !== 'return' && item.status !== 'newplans' && !item.dashboard_hide_tab
            })
          }
        }
        return items
      },
      getReturnFeedbackType() {
        const items = Utils.getFeedbackTypes()
        if (items) {
          const feed = items.find(function (item) {
            return item.status === 'return'
          })
          if (feed) {
            return feed.id
          }
        }
        return null
      },
      getNewPlansFeedbackType() {
        const items = Utils.getFeedbackTypes()
        if (items) {
          const feed = items.find(function (item) {
            return item.status === 'newplans'
          })
          if (feed) {
            return feed.id
          }
        }
        return null
      },
      getFeedbackTypesDict(user) {
        const items = user && user.configurations && user.configurations.feedback && user.configurations.feedback.tabs
        if (items) {
          const dict = {}
          items.forEach(function (item) {
            dict[item.value] = window.strings[item.tab] ? window.strings[item.tab] : item.tab
          })
          return dict
        }
        return null
      },
      getRegisterDetails(config) {
        const details = []
        if (config && config.registers_details) {
          config.registers_details.forEach(function (item) {
            let title = window.strings[item]
            let items = null
            if (item === 'mobile_phone') {
              title = window.strings['contact']
            }
            if (item === 'height') {
              title = window.strings['height_cm']
            }
            if (item === 'paym_subt') {
              title = window.strings['plan']
            }
            if (item === 'gender') {
              items = Utils.getGenders()
            }
            if (item === 'physical_condition') {
              items = Utils.getPhysicalConditions()
            }
            if (item === 'goal') {
              items = Utils.getGoals()
            }
            details.push({
              id: item,
              title: title,
              items: items,
              editable: config.registers_edit_details ? config.registers_edit_details.find(function (it) {
                return it.id === item
              }) : false
            })
          })
        }
        return details
      },
      getAlerts(payload, callback) {
        const self = this

        if (payload.next_feedbacks && this.showNextFeedbacksDate && this.nextFeedbacksDate) {
          payload.next_feedbacks_date = this.nextFeedbacksDate
        }

        if (payload.next_payments && this.nextPaymentsDate) {
          payload.next_payments_date = this.nextPaymentsDate
        }

        Api.getAlerts(payload, function (response) {
          if (response.success) {
            if (payload.registers) {
              if (self.user && Utils.isEmployee(self.user.type) && self.showAdminSubsCheck) {
                self.registers = response.data.registers.filter(function (item) {
                  return self.registerPaymentActiveValues.indexOf(item.paym_sub_status) > -1
                })
              } else {
                self.registers = response.data.registers
              }
              const incompleteRegisters = self.registers.filter(function (item) {
                return self.registerPaymentActiveValues.indexOf(item.paym_sub_status) === -1
              })
              self.showRegistersTableTabs = !self.hideRegisterTabs && incompleteRegisters.length ? true : false
              if (self.showCompleteRegistersChatMessageTab) {
                self.setCompleteRegistersChatLastMsg()
              }
            }
            if (payload.initial_quizs) {
              self.initialQuizFeedbacks = response.data.initial_quizs
            }
            if (payload.pending_payments) {
              self.pendingPayments = response.data.pending_payments
            }
            if (payload.received_payments) {
              self.receivedPayments = response.data.received_payments.map(p => {
                p.payment_id = p.id
                p.id = p.user_id + '_' + p.id
                return p
              })
            }
            if (payload.trng_plans) {
              self.trngPlans = response.data.trng_plans
            }
            if (payload.feedbacks) {
              self.feedbacks = []
              let hasProgressingFeedbacks = false
              response.data.feedbacks.forEach(function (fb) {
                if (fb.time) {
                  fb.date += ' ' + fb.time
                }
                if (self.physicalEvaluationStatusDict[fb.status] && self.physicalEvaluationStatusDict[fb.status].status === 'progressing') {
                  hasProgressingFeedbacks = true
                }
                self.feedbacks.push(fb)
              })
              if (hasProgressingFeedbacks) {
                if (!self.feedbackTypes) {
                  self.feedbackTypes = []
                }
                self.feedbackTypes.unshift({
                  id: 0,
                  name: window.strings['finalized'],
                  tab: window.strings['finalized'],
                  status: 'finalized'
                }, {
                  id: 1,
                  name: window.strings['progressing'],
                  tab: window.strings['progressing'],
                  status: 'progressing'
                })
                self.showInProgressFeedbacks = true
              }
            }
            if (payload.phy_evals) {
              self.phyEvals = response.data.phy_evals
            }
            if (payload.nutri_plans) {
              self.nutriPlans = response.data.nutri_plans
            }
            if (payload.payment_proofs) {
              self.paymentProofs = response.data.payment_proofs.map(p => {
                p.payment_id = p.id
                p.id = p.user_id + '_' + p.id
                return p
              })
            }
            if (payload.birthdays) {
              self.birthdays = response.data.birthdays
            }
            if (payload.next_feedbacks) {
              self.nextFeedbacks = response.data.next_feedbacks.sort(function (a, b) {
                if (a.date < b.date) return -1
                if (a.date > b.date) return 1
                return 0
              })
            }
            if (payload.pending_feedbacks) {
              self.pendingFeedbacks = response.data.pending_feedbacks.sort(function (a, b) {
                if (a.feedback_day < b.feedback_day) return -1
                if (a.feedback_day > b.feedback_day) return 1
                return 0
              })
            }
            if (payload.next_payments) {
              self.nextPayments = response.data.next_payments
            }
            if (payload.totals && response.data.totals) {
              self.activeClients = response.data.totals.active_clients + ''
              self.suspendedClients = response.data.totals.suspended_clients + ''
              self.blockedClients = response.data.totals.blocked_clients + ''
              self.registersTotal = response.data.totals.registers_total + ''
            }
            if (response.data.pt_payment) {
              self.ptPayment = response.data.pt_payment
              if (!window.ptPaymentWarningDone) {
                window.ptPaymentWarningDone = true
                self.dialogPtPaymentRemarks = true
              }
            }
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }

          for (const key in payload) {
            self.tablesLoaded.push(key)
          }

          if (callback) {
            callback()
          }
        })
      },
      setCompleteRegistersChatLastMsg() {
        const self = this
        const chatsIds = []
        this.registers.forEach(function (item) {
          if (item.chat_id && self.registerPaymentActiveValues.indexOf(item.paym_sub_status) > -1) {
            chatsIds.push(item.chat_id)
          }
        })
        if (chatsIds.length) {
          ChatApi.getChatRoomsIds({
            ids: chatsIds
          }, function (response) {
            if (response.success) {
              const roomsIds = []
              const roomsObj = {}
              response.data.forEach(function (rm) {
                roomsIds.push(rm.chat_id)
                roomsObj[rm.user_id] = rm.chat_id
              })
              if (roomsIds.length) {
                ChatApi.getChatRoomsLastMsg({
                  ids: roomsIds
                }, function (resp) {
                  if (resp.success) {
                    for (let r = 0; r < self.registers.length; r++) {
                      const res = resp.data.find(function (it) {
                        return it.id === roomsObj[self.registers[r].chat_id]
                      })
                      if (res && res.last_msg_utc) {
                        self.registers[r].chat_last_msg = res.last_msg_utc
                      }
                    }
                  } else {
                    self.$alert(
                      resp.message,
                      '',
                      'warning',
                      Utils.getAlertOptions()
                    )
                  }
                })
              }
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      getSubscriptionLabel(id) {
        const slitId = id.split('#')

        if (id.indexOf('day') > -1) {
          if (id === '1#day') {
            return window.strings['subscription_diary']
          } else {
            return window.strings['subscription'] + ' ' + window.strings['by'] +
              ' ' + slitId[0] + ' ' + window.strings['in'] + ' ' +
              slitId[0] + ' ' + window.strings['days']
          }
        } else if (id.indexOf('month') > -1) {
          if (id === '1#month') {
            return window.strings['subscription_monthly']
          } else {
            return window.strings['subscription'] + ' ' + window.strings['by'] +
              ' ' + slitId[0] + ' ' + window.strings['in'] + ' ' +
              slitId[0] + ' ' + window.strings['months']
          }
        }

        return id
      },
      getPaymSubStatus(status) {
        const options = Utils.getRegisterPaymentStatus()
        let item = null

        if (options) {
          item = options.find(function (option) {
            return option.value === status
          })
        }

        if (item) {
          return item
        } else {
          if (status === 0) {
            return {
              label: window.strings['manual'],
              color: ''
            }
          } else {
            return {
              label: window.strings['status'] + ': ' + status,
              color: ''
            }
          }
        }
      },
      refreshHotmartConnections(register) {
        const self = this
        self.$isLoading(true)
        Api.refreshHotmartConnections({
          email: register.email
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.openRegister(register)
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      validateRegisterParameter() {
        if (this.$route.query && this.$route.query.register_id !== undefined) {
          this.openRegister({
            id: this.$route.query.register_id
          })
          const url = new URL(window.location.href)
          url.hash = url.hash.replace(/([&?])register_id=.*?(&|$)/, '$1').replace(/(^[?&])|(&$)/g, '')
          history.replaceState(null, null, url.href)
        }
      },
      openRegister(register) {
        const self = this
        this.register = null
        this.currentRegisterPhotoIndex = 0
        this.registerTabActive = 0
        this.dialogRegister = true

        Api.getClientRegister(register.id, function (response) {
          if (response.success) {
            if (self.ptQuestions.length) {
              getRegisterPayment(response.data[0])
            } else {
              self.setValidateRegisterFields(function () {
                getRegisterPayment(response.data[0])
              })
            }
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })

        function getRegisterPayment(registerData) {
          Api.getRegisterPayment(register.id, function (response) {
            if (response.success) {
              if (response.data && response.data[0]) {
                registerData.value = response.data[0].value
                self.setRegister(registerData, response.data[0])
              } else {
                self.setRegister(registerData)
              }
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      setRegister(register, payment) {
        const self = this

        // Empty data
        this.registerAssetsDone = false
        this.registerVideosDone = false
        this.registerQuestions = []
        this.registerPhotos = []
        this.registerVideos = []
        for (let i = 0; i < this.registerDetails.length; i++) {
          this.registerDetails[i].value = ''
        }

        // Set register
        this.register = register

        // Set details
        let age = 0
        let hasNotes = false
        for (let i = 0; i < this.registerDetails.length; i++) {
          if (this.registerDetails[i].items) {
            for (let t = 0; t < this.registerDetails[i].items.length; t++) {
              if (this.registerDetails[i].items[t].value === this.register[this.registerDetails[i].id]) {
                this.registerDetails[i].value = this.registerDetails[i].items[t].label
                break
              }
            }
          } else {
            this.registerDetails[i].value = this.register[this.registerDetails[i].id]

            if (this.registerDetails[i].id === 'birth_date') {
              const ageDifMs = Date.now() - new Date(this.registerDetails[i].value).getTime()
              const ageDate = new Date(ageDifMs)
              age = Math.abs(ageDate.getUTCFullYear() - 1970)
            }

            if (this.registerDetails[i].id === 'age') {
              this.registerDetails[i].value = age
            }
          }
          if (this.registerDetails[i].id === 'notes') {
            hasNotes = this.registerDetails[i].value
            this.registerDetails[i].value = ''
          }
          if (this.registerDetails[i].id === 'employee') {
            this.registerEmployeeId = null
            try {
              const registerNotes = this.register.notes ? JSON.parse(this.register.notes) : null
              this.registerDetails[i].value = registerNotes && registerNotes.employee ? registerNotes.employee : ''
              this.registerEmployeeId = this.registerDetails[i].value
            } catch { }
          }
        }

        // Set notes
        if (hasNotes) {
          this.setRegisterNotes(hasNotes)
        }

        // Set questions
        if (typeof this.register.questions === 'string') {
          this.register.questions = JSON.parse(this.register.questions)
        }

        for (let a = 0; a < this.register.questions.length; a++) {
          const ptQuestion = this.ptQuestions.find(function (item) {
            return item.id === self.register.questions[a].id
          })
          if (ptQuestion) {
            this.registerQuestions.push({
              title: ptQuestion.title,
              value: this.register.questions[a].answer,
            })
          } else {
            this.registerQuestions.push({
              title: this.register.questions[a].id,
              value: this.register.questions[a].answer,
            })
          }
        }

        // Set measurements
        if (this.register && this.register.measurements) {
          this.register.measurements = JSON.parse(this.register.measurements)

          for (let q = 0; q < this.ptMeasurements.length; q++) {
            for (let a = 0; a < this.register.measurements.length; a++) {
              if (this.register.measurements[a].id === this.ptMeasurements[q].id) {
                this.register.measurements[a].parent = this.ptMeasurements[q].parent
                this.register.measurements[a].title = this.ptMeasurements[q].title
                break
              }
            }
          }
        }

        // Set payment
        this.registerPayment = payment
        this.registerCurrency = Utils.getCurrency(payment)
      },
      setRegisterNotes(notes) {
        const self = this
        if (Object.keys(this.foods).length) {
          setHtml()
        } else {
          Api.getFoods({
            useCache: true,
          }, function (response) {
            if (response.success) {
              const foods = {}
              response.data.forEach(function (food) {
                foods[food.id] = food.name
              })
              self.foods = foods
              setHtml()
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }

        function setHtml() {
          let html = ''
          try {
            if (notes) {
              const data = JSON.parse(notes)
              html = '<div>'
              if (data.accepted_foods) {
                html += '<div>'
                html += '<div class="mt-2 success--text">' + window.strings['accepted_foods'] + '</div>'
                let acceptedFoodsAdded = []
                data.accepted_foods.forEach(function (id) {
                  const name = self.foods[id] ? self.foods[id].trim() : null
                  if (name && acceptedFoodsAdded.indexOf(name) === -1) {
                    acceptedFoodsAdded.push(name)
                  }
                })
                acceptedFoodsAdded = acceptedFoodsAdded.sort()
                acceptedFoodsAdded.forEach(function (it) {
                  html += '<div>• ' + it + '</div>'
                })
                html += '</div>'
              }
              if (data.unaccepted_foods) {
                html += '<div>'
                html += '<div class="mt-2 warning--text">' + window.strings['unaccepted_foods'] + '</div>'
                let unacceptedFoodsAdded = []
                data.unaccepted_foods.forEach(function (id) {
                  const name = self.foods[id] ? self.foods[id].trim() : null
                  if (name && unacceptedFoodsAdded.indexOf(name) === -1) {
                    unacceptedFoodsAdded.push(name)
                  }
                })
                unacceptedFoodsAdded = unacceptedFoodsAdded.sort()
                unacceptedFoodsAdded.forEach(function (it) {
                  html += '<div>• ' + it + '</div>'
                })
                html += '</div>'
              }
              if (data.intolerant_foods) {
                html += '<div>'
                html += '<div class="mt-2 warning--text">' + window.strings['intolerant_foods'] + '</div>'
                let intolerantFoodsAdded = []
                data.intolerant_foods.forEach(function (id) {
                  const name = self.foods[id] ? self.foods[id].trim() : null
                  if (name && intolerantFoodsAdded.indexOf(name) === -1) {
                    intolerantFoodsAdded.push(name)
                  }
                })
                intolerantFoodsAdded = intolerantFoodsAdded.sort()
                intolerantFoodsAdded.forEach(function (it) {
                  html += '<div>• ' + it + '</div>'
                })
                html += '</div>'
              }
              if (data.include_supplements) {
                html += '<div>'
                html += '<div class="mt-2"><span class="error--text">' + window.strings['include_supplements'] + ': </span>' + data.include_supplements + '</div>'
                html += '</div>'
              }
            }
          } catch { }
          for (let i = 0; i < self.registerDetails.length; i++) {
            if (self.registerDetails[i].id === 'notes') {
              self.registerDetails[i].value = html
              self.$set(self.registerDetails, i, self.registerDetails[i])
              break
            }
          }
        }
      },
      changeRegisterTab: function () {
        if (this.registerTabs[this.registerTabActive].id === 'photos') {
          if (!this.registerAssetsDone) {
            this.getRegisterAssets()
          }
          this.registerAssetsDone = true
        }
        if (this.registerTabs[this.registerTabActive].id === 'videos') {
          if (!this.registerVideosDone) {
            this.getRegisterVideos()
          }
          this.registerVideosDone = true
        }
      },
      getRegisterAssets: function () {
        const self = this
        this.registerAssetsProcessing = true
        Api.getClientRegisterAssets(this.register.id, function (response) {
          self.registerAssetsProcessing = false
          if (response.success) {
            const assets = response.data
            for (let i = 0; i < assets.length; i++) {
              if (assets[i].type > 0) {
                self.registerVideos.push(assets[i].photo)
              } else {
                self.registerPhotos.push(assets[i])
              }
            }
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      getRegisterVideos: function () {
        const self = this
        if (this.registerVideos && this.registerVideos.length) {
          getVideo(0)
        }

        function getVideo(index) {
          if (!self.registerVideos[index]) {
            return true
          }
          StorageApi.getVideo(parseInt(self.registerVideos[index]), function (response) {
            if (response.success && response.data[0]) {
              self.registerVideos[index] = response.data[0].file
              self.$set(self.registerVideos, index, self.registerVideos[index])
            }
            index += 1
            getVideo(index)
          })
        }
      },
      setValidateRegisterFields(callback) {
        const self = this

        // Pedro / Tiago
        if (axios.defaults.ptId === -2 || axios.defaults.ptId === -3) {
          Api.getRegisterQuestions(function (response) {
            if (response.success) {
              for (let i = 0; i < response.data.length; i++) {
                self.ptQuestions.push({
                  id: response.data[i].id,
                  title: response.data[i].question,
                  value: '',
                })
              }
              if (callback) {
                callback()
              }
            }
          })
        } else {
          Api.getRegisterConfig(function (response) {
            if (response.success) {
              for (let s = 0; s < response.data.steps.length; s++) {
                if (response.data.steps[s].id === 'questions') {
                  for (let q = 0; q < response.data.steps[s].fields.length; q++) {
                    self.ptQuestions.push({
                      id: response.data.steps[s].fields[q].id,
                      title: response.data.steps[s].fields[q].title,
                      value: '',
                    })
                  }
                }
                if (response.data.steps[s].id === 'measurements') {
                  for (let q = 0; q < response.data.steps[s].fields.length; q++) {
                    self.ptMeasurements.push({
                      id: response.data.steps[s].fields[q].id,
                      parent: response.data.steps[s].fields[q].parent,
                      title: response.data.steps[s].fields[q].title,
                      value: '',
                    })
                  }
                }
              }
              if (callback) {
                callback()
              }
              return true
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      cancelRegister() {
        this.register = null
        this.dialogRegister = false
      },
      validatePaymentClient() {
        const self = this
        const payload = {
          id: this.register.id,
          paym_sub: '',
          paym_sub_status: this.registerPaymentActiveValue,
        }
        this.$isLoading(true)

        Api.updateClientRegister(payload, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.dialogRegister = false
            self.getAlerts({
              registers: true
            })
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      validateClient() {
        this.dialogRegister = false
        this.payday = null

        if (this.user && this.user.configurations && this.user.configurations.payment && this.user.configurations.payment.payday &&
          (!this.register.paym_sub || this.register.paym_sub_status !== this.registerPaymentActiveValue)
        ) {
          this.dialogPayday = true
        } else {
          this.confirmValidateClient(null)
        }
      },
      confirmValidateClient(payday) {
        const self = this
        const payload = {
          userId: this.register.id,
          payday: payday,
        }
        this.$isLoading(true)

        Api.validateClient(payload, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.dialogPayday = false
            self.payday = null
            self.refreshRegisterClients()
            self.assignClientEmployees(response.data)
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      refuseClient() {
        const self = this
        this.$isLoading(true)

        Api.refuseClient(this.register.id, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.dialogRegister = false
            self.refreshRegisterClients()
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      refreshRegisterClients() {
        for (let i = 0; i < this.registers.length; i++) {
          if (this.registers[i].id === this.register.id) {
            this.registers.splice(i, 1)
            break
          }
        }
      },
      closeRegister() {
        this.dialogRegister = false
      },
      openClient: function (item, tab) {
        const self = this
        this.$isLoading(true)
        Api.getUserWithId({
          id: item.id ? item.id : item.user_id
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            const client = Utils.encodeClient(response.data[0])
            Utils.setStorage('client', client)
            window.showPaydayAlert = true
            window.clientPageCacheList = null
            self.$router.navigate.push({ path: '/home/client', query: { tab: tab ? tab : 'details' } })
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      openPhysicalEvaluation: function (client) {
        window.feedbackPhysicalEvaluation = client.pe_id
        this.openClient({
          id: client.user_id,
        }, 'physical_evaluation')
      },
      openPayment: function (payment) {
        window.paymentToOpen = payment.payment_id
        this.openClient({
          id: payment.user_id,
        }, 'payments')
      },
      updateRegisterPhotos: function (event) {
        const self = this
        if (event) {
          this.$isLoading(true)
          const file = event.srcElement.files[0]
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = function (e) {
            const image = new Image()
            image.src = reader.result
            image.onload = function () {
              if (image.width < self.imageMaxSize && image.height < self.imageMaxSize) {
                saveImage(e.target.result)
              } else {
                saveImage(resizeImage(image))
              }
            }
          }
          reader.onerror = function () {
            self.$isLoading(false)
            self.$alert(
              window.strings['common_error'],
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        }

        function resizeImage(image) {
          const canvas = document.createElement('canvas')
          let width = image.width
          let height = image.height
          if (width > height) {
            if (width > self.imageMaxSize) {
              height *= self.imageMaxSize / width
              width = self.imageMaxSize
            }
          } else {
            if (height > self.imageMaxSize) {
              width *= self.imageMaxSize / height
              height = self.imageMaxSize
            }
          }
          canvas.width = width
          canvas.height = height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(image, 0, 0, width, height)
          return canvas.toDataURL()
        }

        function saveImage(image) {
          const payload = {
            id: self.register.id,
            assets: []
          }
          self.registerPhotos.forEach(function (item, index) {
            payload.assets.push({
              register_id: self.register.id,
              pt_id: axios.defaults.ptId,
              photo: self.currentRegisterPhotoIndex === index ? image : item.photo
            })
          })
          Api.updateClientRegisterAssets(payload, function (response) {
            self.$isLoading(false)
            if (response.success) {
              document.getElementById('register-input-photos').value = ''
              self.registerPhotos = []
              self.getRegisterAssets()
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      downloadImage: function (image) {
        const a = document.createElement('a')
        a.href = image
        a.download = 'image.png'
        a.click()
      },
      copyTextToClipboard: function (text) {
        navigator.clipboard.writeText(text)
      },
      openClientChat: function (item, isRegister) {
        if (!item) {
          return false
        }
        Utils.openClientChat({
          scope: this,
          client: item,
          isRegister: isRegister
        })
      },
      openClientStatus2Warning: function (item) {
        const message = item.status2 === '#FF0000' ?
          'Precisa de muita motivação desiste muito facilmente!' :
          'Tem motivação mas não sabe qual o treino e alimentação certa para conseguir atingir os resultados'
        this.$alert(
          message,
          '',
          'info',
          Utils.getAlertOptions()
        )
      },
      deleteRegisterProof: function (item) {
        const self = this
        this.$confirm(
          window.strings['want_delete'] + '?',
          '',
          'warning',
          Utils.getAlertOptions(true, true)
        ).then(() => {
          self.$isLoading(true)
          Api.deleteRegisterPayment(item.id, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.openRegister(self.register)
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }).catch(() => { })
      },
      editRegister: function (item) {
        const self = this
        const register = this.register
        if (item.editable) {
          this.cancelRegister()
          this.$prompt(
            this.str[item.editable.label] ? this.str[item.editable.label] : item.editable.label,
            register[item.editable.id],
            '',
            '',
            Utils.getAlertOptions()
          ).then(value => {
            if (value) {
              self.$isLoading(true)
              const payload = {
                id: register.id
              }
              if (item.editable.id === 'email') {
                payload[item.editable.id] = value.toLowerCase().trim()
              } else {
                payload[item.editable.id] = value
              }
              Api.updateClientRegister(payload, function (response) {
                self.$isLoading(false)
                if (response.success) {
                  if (item.editable.id === 'email') {
                    self.updateUserChatEmail(response.data)
                  }
                  self.openRegister(register)
                } else {
                  self.openRegister(register)
                  self.$alert(
                    response.message,
                    '',
                    'warning',
                    Utils.getAlertOptions()
                  )
                }
              })
            } else {
              self.openRegister(register)
            }
          }).catch(() => {
            self.openRegister(register)
          })
        }
      },
      updateUserChatEmail(register) {
        const self = this
        if (this.user && this.user.configurations && this.user.configurations.chat && !this.user.configurations.chat.version_2 && register.chat_id) {
          ChatApi.updateChatUser({
            id: register.chat_id,
            email: register.email
          }, function (response) {
            if (!response.success) {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      subscribeMqttTopic() {
        if (this.user && this.user.configurations && this.user.configurations.mqtt && this.user.configurations.mqtt.dashboard) {
          if (window.mqttDashboard) {
            window.mqttDashboard.subscribe(axios.defaults.dev ? 'dev/dashboard/' + axios.defaults.ptId + '/alerts' : 'dashboard/' + axios.defaults.ptId + '/alerts')
          }
          window.addEventListener('message', this.receiveMqttData)
        }
      },
      unsubscribeMqttTopic() {
        if (this.user && this.user.configurations && this.user.configurations.mqtt && this.user.configurations.mqtt.dashboard) {
          if (window.mqttDashboard) {
            window.mqttDashboard.unsubscribe(axios.defaults.dev ? 'dev/dashboard/' + axios.defaults.ptId + '/alerts' : 'dashboard/' + axios.defaults.ptId + '/alerts')
          }
          window.removeEventListener('message', this.receiveMqttData)
        }
      },
      receiveMqttData(event) {
        try {
          if (event && event.data && event.data.indexOf('setImmediate') !== 0) {
            const data = JSON.parse(event.data)
            if (data && data.event === 'dashboard-notification' && data.data) {
              const action = data.data
              if (action && action.id) {
                if (action.id === 'registers') {
                  this.getAlerts({
                    registers: true
                  })
                  return true
                }
                if (action.id === 'feedbacks') {
                  this.getAlerts({
                    feedbacks: true
                  })
                  return true
                }
                if (action.id === 'open-client-register') {
                  this.openRegister(action.data)
                  return true
                }
              }
            }
          }
        } catch { }
      },
      assignClientEmployees(client) {
        if (this.user && this.user.configurations && this.user.configurations.employees && this.user.configurations.employees.assign_register_validated) {
          if (this.registerEmployeeId) {
            this.confirmAssignEmployees({
              client: client,
              employee1: parseInt(this.registerEmployeeId)
            })
          } else {
            this.openAssignEmployees(client)
          }
        }
      },
      openAssignEmployees(client) {
        this.assignEmployeesData = {
          client: client,
          employee1: null,
          employee2: null,
        }
        if (this.employeesList.length) {
          this.dialogAssignEmployees = true
        } else {
          this.getEmployees()
        }
      },
      getEmployees: function () {
        const self = this
        this.$isLoading(true)
        Api.getEmployees({
          fields: ['id', 'name']
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            let employeesList = [{
              id: null,
              name: ''
            }]
            employeesList = employeesList.concat(response.data)
            self.employeesList = employeesList
            self.employeesData = response.data
            self.employeesDict = response.data.reduce((dict, e) => {
              dict[e.id] = e.name
              return dict
            }, {})
            self.dialogAssignEmployees = true
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      closeAssignEmployees() {
        this.dialogAssignEmployees = false
      },
      confirmAssignEmployees(src) {
        const self = this
        if (!src) {
          src = this.assignEmployeesData
        }
        const data = {
          id: src.client.id,
          employee1: src.employee1,
          employee2: src.employee2,
        }
        this.closeAssignEmployees()
        this.$isLoading(true)
        Api.updateUser(data, function (response) {
          self.$isLoading(false)
          if (!response.success) {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      updateTableItemsPerPage(key, value) {
        if (!window.cacheTableItemsPerPage) {
          window.cacheTableItemsPerPage = {}
        }
        if (!window.cacheTableItemsPerPage.dashboard) {
          window.cacheTableItemsPerPage.dashboard = {}
        }
        window.cacheTableItemsPerPage.dashboard[key] = value
      },
      addRegisterPaymentProof(event) {
        const self = this
        const file = event.srcElement.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function (e) {
          const image = new Image()
          image.src = reader.result
          image.onload = function () {
            if (image.width < self.imageMaxSize && image.height < self.imageMaxSize) {
              self.updateRegisterPaymentProof(e.target.result)
            } else {
              self.updateRegisterPaymentProof(resizeImage(image))
            }
            document.getElementById('register-payment-image').value = ''
          }
        }
        reader.onerror = function () { }

        function resizeImage(image) {
          const canvas = document.createElement('canvas')
          let width = image.width
          let height = image.height
          if (width > height) {
            if (width > self.imageMaxSize) {
              height *= self.imageMaxSize / width
              width = self.imageMaxSize
            }
          } else {
            if (height > self.imageMaxSize) {
              width *= self.imageMaxSize / height
              height = self.imageMaxSize
            }
          }
          canvas.width = width
          canvas.height = height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(image, 0, 0, width, height)
          return canvas.toDataURL()
        }
      },
      updateRegisterPaymentProof: function (proof) {
        const self = this
        const obj = {
          id: this.register.id,
          type: 0,
          proof: proof
        }
        if (this.addRegisterPaymentFields) {
          this.addRegisterPaymentFields.forEach(function (f) {
            if (f.value) {
              obj[f.id] = f.inputType === 'numeric' ? parseFloat(f.value) : f.value
            }
          })
        }
        this.$isLoading(true)
        Api.sendRegisterPayment(obj, function (response) {
          if (response.success) {
            self.refreshRegisterPayment()
          } else {
            self.$isLoading(false)
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      generateInvoice: function () {
        const self = this
        this.$isLoading(true)
        Api.generateRegisterPaymentInvoice({
          id: this.registerPayment.id
        }, function (response) {
          if (response.success) {
            self.refreshRegisterPayment()
          } else {
            self.$isLoading(false)
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      openLink: function (url) {
        window.open(url, '_blank').focus()
      },
      refreshRegisterPayment: function () {
        const self = this
        Api.getRegisterPayment(this.register.id, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.dialogRegister = false
            if (response.data && response.data[0]) {
              self.registerPayment = response.data[0]
              self.registerCurrency = Utils.getCurrency(response.data[0])
            }
            setTimeout(function () {
              self.dialogRegister = true
            })
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      removePendingFeedbackAlert(item) {
        const self = this
        this.$confirm(
          window.strings['are_you_sure_remove_pending_feedback_alert'],
          '',
          'warning',
          Utils.getAlertOptions(true, true)
        ).then(() => {
          let flags = item.flags
          const data = {
            id: item.id,
            flags: flags &= ~(1 << 9)
          }
          self.$isLoading(true)
          Api.updateUser(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              const index = self.pendingFeedbacks.findIndex(function (p) {
                return p.id === item.id
              })
              if (index > -1) {
                self.pendingFeedbacks.splice(index, 1)
              }
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }).catch(() => { })
      },
    },
    beforeDestroy: function () {
      this.unsubscribeMqttTopic()
    },
  }
</script>
